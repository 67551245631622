import { Skeleton } from "@material-ui/lab";
import { Button, Col, List, Space } from "antd";
import React from "react";

function FilterDesktopSkeleTon({ length = 10 }) {
	const data = Array.from(Array(length / 2).keys());

	const renderCateItem = (item) => {
		return (
			<List.Item key={item}>
				<div className="filter-desktop-cate-box">
					<Skeleton
						className="filter-desktop-cate-item"
						animation="wave"
						variant="text"
						width={200}
						height={30}
					/>
					<Skeleton
						className="filter-desktop-cate-item"
						animation="wave"
						variant="text"
						width={150}
						height={30}
					/>
				</div>
			</List.Item>
		);
	};

	return (
		<>
			<Col span={7}>
				<div className="div-bg-pd filter-desktop-skeleton-container">
					<Skeleton
						animation="wave"
						variant="text"
						width={230}
						height={35}
						className="filter-desktop-header"
					/>
					<List
						size="small"
						header={null}
						footer={null}
						dataSource={data}
						renderItem={(item) => renderCateItem(item)}
					/>
				</div>

				<div className="div-bg-pd">
					<Skeleton animation="wave" variant="text" width={230} height={35} />
					<Skeleton animation="wave" variant="text" height={60} />
				</div>

				<div className="div-bg-pd mb-2">
					<Skeleton animation="wave" variant="text" width={230} height={35} />
					<Skeleton animation="wave" variant="text" width={130} height={30} />
					<Skeleton animation="wave" variant="text" width={200} height={30} />
					<Skeleton animation="wave" variant="text" width={200} height={30} />
					<Skeleton animation="wave" variant="text" width={130} height={30} />
				</div>

				<div className="div-bg-pd mb-2">
					<Skeleton animation="wave" variant="text" width={230} height={35} />
					<Space direction="horizontal" size={[16, 8]} wrap className="mt-2 group-button-rate-6x4g">
						<Skeleton animation="wave" variant="text" width={50} height={40} />
						<Skeleton animation="wave" variant="text" width={50} height={40} />
						<Skeleton animation="wave" variant="text" width={50} height={40} />
						<Skeleton animation="wave" variant="text" width={50} height={40} />
						<Skeleton animation="wave" variant="text" width={50} height={40} />
					</Space>
				</div>
			</Col>
		</>
	);
}

export default FilterDesktopSkeleTon;
