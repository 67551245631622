//
import {
    AppstoreOutlined,
    DownOutlined,
    EditOutlined,
    RightOutlined,
    SearchOutlined,
    SkinOutlined,
    UserOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Avatar, Badge, Button, Col, Divider, Dropdown, Input, Menu, Row, Tag } from "antd";
import classNames from "classnames";
import queryString from "query-string";
import React, { Fragment, memo, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as CategoryAPI from "src/api/categoryAPI";
import searchHistoryAPI from "src/api/searchHistoryAPI";
import { cartItemsCountSelector } from "src/containers/Cart/selector";
import { ModalContext } from "src/contexts/ModalContext";
import { removeAddressCheckedStore } from "src/redux/actions/AddressActions";
import { removeCartStore } from "src/redux/actions/CartActions";
import { removePaymentTypeStore } from "src/redux/actions/OrderActions";
import { removeOrderItemsCheckedStore, setSearchInputStore } from "src/redux/actions/ProductActions";
import { removeAllSearchHistoryStore, removeUserInfoStore, setSearchHistoryStore } from "src/redux/actions/UserActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import defaultImg from "../../../assets/images/logo_ahappi2.png";
import logo from "src/assets/images/logo_ahappi2.png";
import DefaultLogo from "../../../assets/images/logo_ahappi.png";
import SwiperCategoriesSkeleton from "./Skeleton/SwiperCategoriesSkeleton";
import "./style.scss";
import { convertTypeUser } from "src/utils/utils";
const { SubMenu } = Menu;

const HeaderWebsite = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { showDeleteConfirm } = useContext(ModalContext);

    const productStore = useSelector((state) => state.product);
    const { search } = productStore.filters;
    const cartItemsCount = useSelector(cartItemsCountSelector);
    const userStore = useSelector((state) => state.user);

    const userInfo = userStore?.userInfo?.object;
    // console.log("⛄❄️ TrieuNinhHan ~ HeaderWebsite ~ userInfo:", userInfo);
    const isLoggedIn = !!userStore?.userInfo?.token;
    const searchHistoryList = userStore?.searchHistoryList;

    const [visible, setVisible] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [hotCategoryList, setHotCategoryList] = useState([]);
    const [isLoadingCategoryList, setIsLoadingCategoryList] = useState(true);
    const [searchValue, setSearchValue] = useState("product");

    const queryParams = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    const logout = () => {
        dispatch(removeUserInfoStore());
        dispatch(removeCartStore());
        dispatch(removeAddressCheckedStore());
        dispatch(removeOrderItemsCheckedStore());
        dispatch(removePaymentTypeStore());
        window.location.reload();
    };

    const handleChange = (e) => {
        dispatch(setSearchInputStore(e.target.value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const searchName = e.target.search.value.trim();
        if (!searchName) return;

        setVisible(false);
        // Clear search
        // dispatch(setSearchInputStore(''));
        dispatch(
            setSearchHistoryStore({
                searchName,
            }),
        );

        let filters = {};

        if (searchValue === "product") {
            filters.search = searchName;
            const currentParams = queryString.parse(location.search);
            const updatedParams = { ...currentParams, ...filters };
            const updatedQueryString = queryString.stringify(updatedParams);

            console.log(
                "⛄❄️ TrieuNinhHan ~ file: HeaderWebsite.js:100 ~ handleSubmit ~ searchName:",
                updatedQueryString,
            );
            history.push({
                pathname: "/san-pham",
                search: updatedQueryString,
            });
        } else {
            filters.name = searchName;
            history.push({
                pathname: "/tim-kiem-cua-hang",
                search: queryString.stringify(filters),
            });
        }
    };

    const handleClickSearchHistory = (searchName) => {
        dispatch(setSearchInputStore(searchName));

        let filters = {};

        if (searchValue === "product") {
            filters.search = searchName;
            console.log(
                "⛄❄️ TrieuNinhHan ~ file: HeaderWebsite.js:122 ~ handleClickSearchHistory ~ searchName:",
                searchName,
            );
            history.push({
                pathname: "/san-pham",
                ...queryString.parse(location.search),
                search: queryString.stringify(filters),
            });
        } else {
            filters.name = searchName;
            history.push({
                pathname: "/tim-kiem-cua-hang",
                search: queryString.stringify(filters),
            });
        }
    };

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    };

    const handleRemoveSearchHistory = () => {
        const handleOk = () => {
            dispatch(removeAllSearchHistoryStore());
        };

        setVisible(false);
        showDeleteConfirm(null, handleOk, "Xóa lịch sử tìm kiếm");
    };

    const handleSearchByKeyword = (keyword) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: HeaderWebsite.js:151 ~ handleSearchByKeyword ~ keyword:", keyword);
        const filters = {
            search: keyword,
        };

        history.push({
            pathname: "/san-pham",
            search: queryString.stringify(filters),
        });
    };

    const getCategoryList = async () => {
        setIsLoadingCategoryList(true);
        try {
            const categoryRes = await CategoryAPI.getList({
                fields: ["$all", { sub_categories: ["$all", { keywords: ["$all"] }] }],
                limit: 999,
            });
            const newCategoryList = categoryRes.results.objects.rows;
            setCategoryList(newCategoryList);
            setIsLoadingCategoryList(false);
            // console.log('newCategoryList: ', newCategoryList);
        } catch (error) {
            console.log(error);
            setIsLoadingCategoryList(false);
        }
    };

    const getSearchHistory = async () => {
        try {
            const res = await searchHistoryAPI.getSearchKeyword({
                fields: '["$all"]',
                limit: 6,
            });
            const newSearchHistoryList = res?.results?.objects;
            setSearchList(newSearchHistoryList);
            // console.log('newSearchHistoryList: ', newSearchHistoryList);
        } catch (error) {
            console.log(error);
        }
    };

    const getHotCategoryList = async () => {
        try {
            const res = await CategoryAPI.getList({
                fields: ["$all"],
                filter: { hot: true },
            });
            const newHotCategoryList = res?.results?.objects?.rows;
            setHotCategoryList(newHotCategoryList);
            // console.log('newHotCategoryList: ', newHotCategoryList);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getCategoryList();
        getSearchHistory();
        getHotCategoryList();
    }, []);

    useEffect(() => {
        if (location.pathname !== "/san-pham" && location.pathname !== "/tim-kiem-cua-hang") {
            dispatch(setSearchInputStore(""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        dispatch(setSearchInputStore(queryParams.search || queryParams.name || ""));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.search]);

    const menu = (
        <Menu className="menu-search-desktop-container">
            <Menu.Item
                onClick={() => setSearchValue("product")}
                key="1"
                className={classNames({ "active-search": searchValue === "product" })}
            >
                Tìm sản phẩm
            </Menu.Item>
            <Divider className="my-0" />
            <Menu.Item
                onClick={() => setSearchValue("shop")}
                key="2"
                className={classNames({ "active-search": searchValue === "shop" })}
            >
                Tìm cửa hàng
            </Menu.Item>
        </Menu>
    );

    const renderCate = () => {
        return categoryList?.map((item, index) => {
            return (
                <SwiperSlide key={index} className="category-scroll-item-header">
                    <Link to={`/danh-muc/${item?.slug}`} key={item?.id} className="category-scroll-item-header">
                        {item?.title}
                    </Link>
                </SwiperSlide>
            );
        });
    };

    const menuUser = (
        <Menu className="container-header-website">
            <Menu.Item>
                <Link to="/user" className="d-flex link">
                    <Avatar src={userInfo?.avatar || logo} size="large" icon={<UserOutlined />} />
                    <div className="d-flex flex-column ms-3">
                        <strong>{userInfo?.fullname || userInfo?.username}</strong>
                        <Tag color="green" style={{ width: "fit-content" }}>
                            {convertTypeUser(userInfo?.user_type)}
                        </Tag>
                    </div>
                </Link>
            </Menu.Item>
            <hr className="m-0" />
            <Menu.Item>
                <Link to="/orderHistory" className="d-flex justify-content-between w-100 link not-first-item">
                    <div className="header-tab-container">
                        <div className="header-tab-item">Quản lý đơn hàng mua</div>
                        <RightOutlined />
                    </div>
                </Link>
            </Menu.Item>
            {userInfo?.user_type === "SUPPLIER" && (
                <>
                    <hr className="m-0" />
                    <Menu.Item>
                        <Link to="/orderProduct" className="d-flex justify-content-between w-100 link not-first-item">
                            <div className="header-tab-container">
                                <div className="header-tab-item">Quản lý đơn hàng bán</div>
                                <RightOutlined />
                            </div>
                        </Link>
                    </Menu.Item>
                </>
            )}
            <>
                <hr className="m-0" />
                <Menu.Item>
                    <Link to="/collaborators" className="d-flex justify-content-between w-100 link not-first-item">
                        <div className="header-tab-container">
                            <div className="header-tab-item">Quản lý tài sản</div>
                            <RightOutlined />
                        </div>
                    </Link>
                </Menu.Item>
            </>

            {userInfo?.user_type === "SUPPLIER" && (
                <Fragment>
                    <hr className="m-0" />
                    <Menu.Item>
                        <Link to="/myProducts" className="d-flex justify-content-between w-100 link not-first-item">
                            <div className="header-tab-container">
                                <div className="header-tab-item">Sản phẩm bán của tôi</div>
                                <RightOutlined />
                            </div>
                        </Link>
                    </Menu.Item>
                </Fragment>
            )}
            <hr className="m-0" />
            <Menu.Item>
                <Link to="/userInfo" className="d-flex justify-content-between w-100 link not-first-item">
                    <div className="header-tab-container">
                        <div className="header-tab-item">Thông tin cá nhân</div>
                        <RightOutlined />
                    </div>
                </Link>
            </Menu.Item>
            <hr className="m-0" />
            <Menu.Item>
                <Link to="/address" className="d-flex justify-content-between w-100 link not-first-item">
                    <div className="header-tab-container">
                        <div className="header-tab-item">Địa chỉ</div>
                        <RightOutlined />
                    </div>
                </Link>
            </Menu.Item>
            <hr className="m-0" />
            <Menu.Item>
                <Link to="/policy/supportpolicy" className="d-flex justify-content-between w-100 link not-first-item">
                    <div className="header-tab-container">
                        <div className="header-tab-item">Chính sách hỗ trợ</div>
                        <RightOutlined />
                    </div>
                </Link>
            </Menu.Item>
            {userInfo?.user_type === "USER" && (
                <>
                    <hr className="m-0" />
                    <Menu.Item>
                        <Link to="/collaboratorsRegister" className="link not-first-item">
                            {/* <div className="d-flex justify-content-between w-100" > */}
                            <div className="header-tab-container" style={{ color: "#64b5ec" }}>
                                <div className="header-tab-item" style={{ paddingBottom: 2.5 }}>
                                    Đăng ký trở thành ĐLKD
                                </div>
                                <RightOutlined />
                            </div>
                            {/* </div> */}
                        </Link>
                    </Menu.Item>
                    <hr className="m-0" />
                    <Menu.Item>
                        <Link to="/suppliersRegister" className="link not-first-item">
                            {/* <div className="d-flex justify-content-between w-100"> */}
                            <div className="header-tab-container" style={{ color: "#64b5ec" }}>
                                <div className="header-tab-item" style={{ paddingBottom: 1.5 }}>
                                    Đăng ký trở thành NCC
                                </div>
                                <RightOutlined />
                            </div>
                            {/* </div> */}
                        </Link>
                    </Menu.Item>
                </>
            )}
            <Menu.Item>
                <Button type="primary" className="w-100" onClick={logout}>
                    Đăng xuất
                </Button>
            </Menu.Item>
        </Menu>
    );

    const renderMenuCate = () => {
        return categoryList?.map((item) => {
            if (item?.sub_categories?.length > 0) {
                // console.log('item: ', item);
                return (
                    <SubMenu
                        className="SubMenu-custom-css"
                        key={item?.id}
                        title={item?.title}
                        onTitleClick={() => history.push(`/danh-muc/${item?.slug}`)}
                        icon={
                            (
                                <Icon
                                    component={() => (
                                        <img
                                            src={item?.image}
                                            width={24}
                                            height={24}
                                            alt={item?.title}
                                            style={{
                                                transform: "translateY(-3px)",
                                                objectFit: "cover",
                                            }}
                                        />
                                    )}
                                />
                            ) || <SkinOutlined />
                        }
                    >
                        <Row gutter={16} style={{ paddingRight: "10px" }}>
                            {item?.sub_categories?.map((ch) => {
                                let span = 24 / item?.sub_categories?.length;

                                return (
                                    <Col key={ch?.id} span={item?.sub_categories?.length <= 3 ? span : 8}>
                                        <Menu.ItemGroup
                                            key={ch?.id}
                                            title={
                                                <div
                                                    onClick={() => history.push(`/danh-muc/${item?.slug}/${ch?.slug}`)}
                                                    style={{
                                                        "white-space": "nowrap",
                                                    }}
                                                >
                                                    {ch?.title}
                                                </div>
                                            }
                                            className="title-menu-header-website"
                                            style={{
                                                cursor: "pointer",
                                                width: "fit-content",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {ch?.keywords?.map((c) => {
                                                //console.log('c: ', c);
                                                return (
                                                    <Menu.Item
                                                        onClick={() => handleSearchByKeyword(c?.keyword)}
                                                        key={c?.id}
                                                    >
                                                        {c?.keyword}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu.ItemGroup>
                                    </Col>
                                );
                            })}
                        </Row>
                    </SubMenu>
                );
            }
        });
    };

    const menuCate = <Menu>{renderMenuCate()}</Menu>;

    const renderSwiperSlide = () => {
        return hotCategoryList?.slice(0, 6).map((item, index) => {
            return (
                <SwiperSlide key={index} className="flex-column">
                    <Link
                        to={`/danh-muc/${item?.slug}`}
                        style={{
                            padding: "5px",
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
                            borderRadius: "16px",
                            margin: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => setVisible(false)}
                    >
                        <img src={item?.image || defaultImg} width={48} height={48} alt={item?.title} />
                    </Link>
                    <p style={{ margin: 0 }}>{item.title}</p>
                </SwiperSlide>
            );
        });
    };

    const renderPopularSearch = () => {
        return searchList?.map((item, index) => {
            return (
                <Col key={index} span={8} className="gutter-row">
                    <div
                        className="div-popular-search-website-header d-flex pointer align-items-center"
                        onClick={() => {
                            handleSearchByKeyword(item?.keyword);
                            setVisible(false);
                        }}
                    >
                        <img
                            src={item?.exampleProduct?.thumbnail || defaultImg}
                            width={45}
                            height={45}
                            alt="defaultImg"
                            style={{ margin: "auto 0", objectFit: "cover", padding: "4px" }}
                        />
                        <p style={{ fontSize: "14px", fontWeight: 500, margin: 0 }}>{item?.keyword}</p>
                    </div>
                </Col>
            );
        });
    };

    const renderMenuSearch = () => {
        return (
            <>
                {/* <div
					className="d-flex justify-content-between"
					style={{ backgroundColor: '#E6EAEE', padding: '16px' }}
				>
					<strong style={{ color: '#EB4335' }}>01-10 Giảm sốc đến 50%</strong>
					<img src={saveSearchHeaderImg} alt="saveSearchHeaderImg" />
				</div> */}

                <div style={{ padding: "16px" }}>
                    <div className="d-flex justify-content-between mb-4">
                        <strong>Lịch sử tìm kiếm</strong>
                        <button
                            onClick={handleRemoveSearchHistory}
                            className={classNames("btn-remove-history-search", {
                                "history-search-empty": searchHistoryList.length <= 0,
                            })}
                            disabled={searchHistoryList.length <= 0}
                        >
                            Xoá lịch sử
                        </button>
                    </div>
                    {searchHistoryList?.map((item, index) => (
                        <p
                            key={index}
                            style={{ color: "#808080", cursor: "pointer" }}
                            onClick={() => handleClickSearchHistory(item?.searchName)}
                        >
                            <SearchOutlined /> {item?.searchName}
                        </p>
                    ))}

                    <Divider className="my-2" />

                    <strong>Tìm kiếm phổ biến</strong>
                    <Row gutter={[8, 8]} style={{ width: "545px", margin: "16px 0" }}>
                        {renderPopularSearch()}
                    </Row>

                    <Divider className="my-2" />
                    <div className="d-flex justify-content-between title mb-3">
                        <strong>Danh Mục Nổi Bật</strong>
                        {hotCategoryList?.length > 6 && (
                            <span
                                style={{ color: "#547DBF", cursor: "pointer" }}
                                onClick={() => history.push("/danh-muc")}
                            >
                                Xem thêm <RightOutlined />
                            </span>
                        )}
                    </div>
                    <div className="swiper-hcya4" style={{ marginTop: "16px" }}>
                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={30}
                            slidesPerColumnFill="row"
                            pagination={{
                                clickable: true,
                            }}
                            slidesPerColumn={1}
                            allowTouchMove={false}
                            // slidesPerColumn={window.innerWidth < 576 ? (data?.length < 6 ? 1 : 2) : 1}
                            // breakpoints={{
                            //     "640": {
                            //         "slidesPerView": 2,
                            //         "spaceBetween": 20
                            //     },
                            //     "768": {
                            //         "slidesPerView": 4,
                            //         "spaceBetween": 40
                            //     },
                            //     "1024": {
                            //         "slidesPerView": 5,
                            //         "spaceBetween": 50
                            //     }
                            // }}
                            className="mySwiper"
                        >
                            {renderSwiperSlide()}
                        </Swiper>
                    </div>
                </div>
            </>
        );
    };

    const menuSearch = <Menu className="p-0">{renderMenuSearch()}</Menu>;

    return (
        <div
            className="d-flex flex-column header-container"
            style={{
                backgroundImage: "linear-gradient(to right, #85dbe9, #c0f9ee)",
                margin: "0 -12px",
                padding: "0 12px",
            }}
        >
            <div className="d-flex justify-content-between py-2 container">
                <Link to="/">
                    <img
                        src={DefaultLogo}
                        width={110}
                        height={46}
                        alt="logo"
                        style={{ cursor: "pointer", objectFit: "contain" }}
                    />
                </Link>
                <Dropdown overlay={menuCate} trigger={["click"]}>
                    <div
                        className="d-flex"
                        style={{ fontSize: "12px", width: "120px", padding: "5px 0", cursor: "pointer" }}
                        onClick={(e) => e.preventDefault()}
                    >
                        <AppstoreOutlined style={{ fontSize: "20px", margin: "6px", paddingBottom: 6 }} />
                        <strong>DANH MỤC SẢN PHẨM</strong>
                    </div>
                </Dropdown>

                <div className="d-flex align-items-stretch">
                    <form onSubmit={handleSubmit} className="d-flex align-items-stretch pt-1 search-form-desktop">
                        <Dropdown
                            overlay={menuSearch}
                            // trigger={["click"]}
                            onClick={() => setVisible(true)}
                            visible={visible}
                            onVisibleChange={handleVisibleChange}
                        >
                            <Input
                                placeholder="Tìm sản phẩm hay danh mục mong muốn"
                                // prefix={<SearchOutlined className="mt-2" />}
                                className="input-header-website"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                name="search"
                            />
                        </Dropdown>

                        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                            <Button style={{ display: "flex", alignItems: "center" }}>
                                Tìm theo... <DownOutlined />
                            </Button>
                        </Dropdown>

                        <Button
                            htmlType="submit"
                            icon={<SearchOutlined />}
                            style={{
                                height: "40px",
                                backgroundColor: "#febd36",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Tìm kiếm
                        </Button>
                    </form>
                </div>

                <div className="d-flex mx-2 avatar-img">
                    <Avatar src={userInfo?.avatar || logo} className="mt-1" size="large" icon={<UserOutlined />} />
                    {isLoggedIn ? (
                        <Dropdown className="ms-2 mt-3 pointer" trigger={["click"]} overlay={menuUser}>
                            <span>
                                {userInfo?.fullname || userInfo?.username} <DownOutlined />
                            </span>
                        </Dropdown>
                    ) : (
                        <p className="ms-2 mt-3" style={{ fontSize: "12px" }}>
                            <button
                                className="pointer"
                                style={{ background: "inherit", border: "none" }}
                                onClick={() => history.push("/login")}
                            >
                                Đăng nhập/Đăng ký
                            </button>
                        </p>
                    )}
                </div>

                <Link to="/cart">
                    <Badge
                        count={cartItemsCount}
                        showZero
                        overflowCount={99}
                        className="mt-3 badge-container"
                        style={{
                            backgroundColor: "#FBBC12",
                            color: "#333",
                            cursor: "pointer",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-cart-fill"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer" }}
                        >
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                    </Badge>
                </Link>
            </div>

            <div className="d-flex justify-content-start flex-wrap div-menu-website py-2">
                <div className="container swiper pointer" style={{ minHeight: "31px" }}>
                    {isLoadingCategoryList ? (
                        <SwiperCategoriesSkeleton />
                    ) : (
                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={35}
                            slidesPerColumnFill="row"
                            slidesPerColumn={1}
                            className="mySwiper"
                        >
                            {renderCate()}
                        </Swiper>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(HeaderWebsite);
