import { Divider, Rate, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { convertPrice } from "src/utils";
import defaultImg from "../../../assets/images/default-img.png";
import souldOutImg from "../../../assets/images/soldout.png";
import "../styles.scss";

const ProductItem = (props) => {
    const { item } = props;

    const userStore = useSelector((state) => state.user);
    const userId = userStore?.userInfo?.object?.id;
    const discount = Math.ceil((item?.price / item?.fake_price) * 100);

    return (
        <>
            <div className="product-item-container">
                <div className="div-product-thumb">
                    <img
                        src={item?.thumbnail || defaultImg}
                        height={170}
                        style={{ objectFit: "contain", width: "100%" }}
                        alt={item?.title || ""}
                    />

                    {item?.best_sale && (
                        <Tag color="#FBBC12" className="tag-best-seller-thumb">
                            Best seller
                        </Tag>
                    )}

                    {discount > 0 && discount < 100 ? (
                        <Tag color="#EB4335" className="tag-contributor-rate-thumb">
                            -{100 - discount}%
                        </Tag>
                    ) : null}

                    {item?.inventory_amount <= 0 && (
                        <img src={souldOutImg} alt="souldOutImg" className="out-of-stock" />
                    )}

                    {item?.user_id === userId && (
                        <Tag color="#EB4335" className="tag-my-product-detail">
                            My
                        </Tag>
                    )}
                </div>

                <div className="div-item-text-product w-100">
                    <p
                        className="mb-0 title-item-text-product"
                        style={{
                            fontSize: "18px",
                            lineHeight: "26px",
                            fontWeight: 600,
                            marginTop: "12px",
                        }}
                    >
                        {item?.title}
                    </p>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="d-flex align-items-center">
                            <div style={{ marginTop: "-3px" }}>
                                <Rate allowHalf disabled value={item?.star} style={{ fontSize: "12px" }} />
                            </div>
                            <Divider type="vertical" />
                            <span className="text-grey-login" style={{ fontSize: "12px" }}>
                                Đã bán {item?.amount_sale}
                            </span>
                        </div>
                    </div>

                    {/* Giá ProductItem Mobile */}
                    <p className="mb-2 fw-bold" style={{ color: "red", fontSize: "16px" }}>
                        {convertPrice(item?.price)}
                    </p>
                    {/* {item?.price * item?.contributor_rate > 0 && (
						<div className="div-hoa-hong" style={props.bigImage && { width: "155px" }}>
							Hoàn tiền: {convertPrice(item?.price * item?.contributor_rate)}
						</div>
					)} */}
                </div>
            </div>
        </>
    );
};

export default ProductItem;
