import { ArrowDownOutlined, ArrowUpOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Menu } from "antd";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as ProductAPI from "src/api/productAPI";
import ButtonLoadMore from "src/components/Button/ButtonLoadMore";
import NoDataMobile from "src/components/NoData/NoDataMobile";
import ProductSkeletonList from "src/components/Skeleton/ProductSkeletonList";
import { handlerScroll } from "src/utils";
import Header from "../../layouts/header/Header";
import ProductList from "../Products/components/ProductList";
import FilterDesktop from "./components/FilterDesktop";
import "./styles.scss";

const ProductsCategory = () => {
    const location = useLocation();
    const history = useHistory();
    const { slugCate, slugSubCate } = useParams();

    const idCate = useMemo(
        () => slugCate?.substring(slugCate?.length - process.env.REACT_APP_ID_LENGTH, slugCate?.length),
        [slugCate],
    );

    const idSubCate = useMemo(
        () => slugSubCate?.substring(slugSubCate?.length - process.env.REACT_APP_ID_LENGTH, slugSubCate?.length),
        [slugSubCate],
    );

    const [isLoadingProduct, setIsLoadingProduct] = useState(true);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [productList, setProductList] = useState([]);
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [page, setPage] = useState(1);
    // const [sortPrice, setSortPrice] = useState({
    // 	order: 'price',
    // 	sort: 'asc',
    // });

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20,
        total: 10,
    });

    const queryParams = useMemo(() => {
        const params = queryString.parse(location.search);

        return {
            ...params,
            page: Number.parseInt(params.page) || 1,
            limit: Number.parseInt(params.limit) || 20,
            // search: params.search || '',
            // orderBy: params.orderBy || 'created_at',
            // sort: params.sort || 'desc',
        };
    }, [location.search]);

    const getProductByCategory = async (queryParams, id) => {
        setIsLoadingProduct(true);
        try {
            const productRes = await ProductAPI.searchProduct({
                fields: '["$all",{"sub_category":["category_id"]}]',
                filter: `{"confirm_by_admin":true,"price":{"$and":[{"$gte":${queryParams.gte || 0}},{"$lte":${
                    queryParams.lte || 9999999999
                }}]},"star":{"$gte":${queryParams.star || 0}},"$sub_category.category_id$":"${id}"}`,
                product: `${queryParams.search || ""}`,
                order: `[["${queryParams.orderBy || "created_at"}", "${queryParams.sort || "desc"}"]]`,
                limit: queryParams.limit,
                page: queryParams.page,
            });

            const newData = productRes.results.objects.rows;
            const newPagination = {
                ...pagination,
                page: productRes?.pagination?.current_page,
                total: productRes?.results?.objects?.count,
            };

            setProductList(newData);
            setPagination(newPagination);
            setIsLoadingProduct(false);
        } catch (error) {
            setIsLoadingProduct(false);
        }
    };

    const getProductBySubCategory = async (queryParams, id) => {
        setIsLoadingProduct(true);
        try {
            const productRes = await ProductAPI.searchProduct({
                fields: '["$all"]',
                filter: `{"price":{"$and":[{"$gte":${queryParams.gte || 0}},{"$lte":${
                    queryParams.lte || 9999999999
                }}]},"star":{"$gte":${queryParams.star || 0}},"sub_category_id":"${id}"}`,
                product: `${queryParams.search || ""}`,
                order: `[["${queryParams.orderBy || "created_at"}", "${queryParams.sort || "desc"}"]]`,
                limit: queryParams.limit,
                page: queryParams.page,
            });

            const newData = productRes.results.objects.rows;
            const newPagination = {
                ...pagination,
                page: productRes?.pagination?.current_page,
                total: productRes?.results?.objects?.count,
            };

            setProductList(newData);
            setPagination(newPagination);
            setIsLoadingProduct(false);
        } catch (error) {
            setIsLoadingProduct(false);
        }
    };

    const getProductLoadMore = async (queryParams, page, id) => {
        setIsLoadMore(true);
        try {
            const productRes = await ProductAPI.searchProduct({
                // điều kiện : là cate ? true : là sub cate
                fields: idCate && !idSubCate ? '["$all",{"sub_category":["category_id"]}]' : '["$all"]',
                filter: `{"price":{"$and":[{"$gte":${queryParams.gte || 0}},{"$lte":${
                    queryParams.lte || 9999999999
                }}]},"star":{"$gte":${queryParams.star || 0}},${
                    idCate && !idSubCate ? '"$sub_category.category_id$"' : '"sub_category_id"'
                }:"${id}"}`,
                product: queryParams.search || "",
                order: `[["${queryParams.orderBy || "created_at"}", "${queryParams.sort || "desc"}"]]`,
                limit: queryParams.limit,
                page: page || 1,
            });

            const newData = productList.concat(productRes.results.objects.rows);
            const newPagination = {
                ...pagination,
                page: productRes?.pagination?.current_page,
                total: productRes?.results?.objects?.count,
            };

            setProductList(newData);
            setPagination(newPagination);
            setIsLoadMore(false);
        } catch (error) {
            setIsLoadMore(false);
        }
    };

    const handleChangePage = (e, page) => {
        const filters = {
            ...queryParams,
            page,
        };

        history.push({
            pathname: history.location.pathname,
            search: queryString.stringify(filters),
        });
    };

    const handleClickFilter = () => {
        // history.push({
        // 	pathname: "/loc-danh-muc",
        // 	search: queryString.stringify(queryParams),
        // });

        if (idCate && !idSubCate) {
            history.push({
                pathname: `/loc-danh-muc/${idCate}`,
                search: queryString.stringify(queryParams),
            });
        }

        if (idCate && idSubCate) {
            history.push({
                pathname: `/loc-danh-muc/${idCate}/${idSubCate}`,
                search: queryString.stringify(queryParams),
            });
        }
    };

    const handleLoadMore = () => {
        setPage(page + 1);
        if (idCate && !idSubCate) {
            getProductLoadMore(queryParams, page + 1, idCate);
        }

        if (idSubCate) {
            getProductLoadMore(queryParams, page + 1, idSubCate);
        }
    };

    const handleChangeSortByPrice = (newSort) => {
        const filters = {
            ...queryParams,
            ...newSort,
            page: 1,
        };

        // console.log('filters: ', filters);
        setPage(1);
        history.push({
            pathname: history.location.pathname,
            search: queryString.stringify(filters),
        });
    };

    const handleChangePriceRange = (newPriceRange) => {
        const filters = {
            ...queryParams,
            gte: newPriceRange.price_gte,
            lte: newPriceRange.price_lte,
            page: 1,
        };

        // console.log('newPriceRange: ', newPriceRange);
        setPage(1);
        history.push({
            pathname: history.location.pathname,
            search: queryString.stringify(filters),
        });
    };

    const handleFilterRate = (newRate) => {
        const filters = {
            ...queryParams,
            star: newRate,
            page: 1,
        };

        setPage(1);
        history.push({
            pathname: history.location.pathname,
            search: queryString.stringify(filters),
        });
    };

    useEffect(() => {
        if (idCate && !idSubCate) {
            getProductByCategory(queryParams, idCate);
        }

        if (idSubCate) {
            getProductBySubCategory(queryParams, idSubCate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, idCate, idSubCate]);

    const handleSortChange = (value) => {
        const data = {
            orderBy: "price",
            sort: value,
        };

        handleChangeSortByPrice(data);
        handlerScroll();
        console.log("value: ", value);
    };

    useEffect(() => {
        handlerScroll();
    }, [history.location]);

    const menu = (
        <Menu className="menu-filter-order-mobile-container">
            <Menu.Item onClick={() => handleSortChange("asc")} key="1" icon={<ArrowUpOutlined />}>
                Lọc theo giá tăng dần
            </Menu.Item>
            <Divider className="my-0" />
            <Menu.Item onClick={() => handleSortChange("desc")} key="2" icon={<ArrowDownOutlined />}>
                Lọc theo giá giảm dần
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="div-default-layout d-flex flex-column products-categories-container">
            <Header title="Sản Phẩm" linkGoback="/danh-muc" />

            {window.innerWidth >= 768 ? (
                <FilterDesktop
                    data={productList}
                    pagination={pagination}
                    queryParams={queryParams}
                    isLoadingProduct={isLoadingProduct}
                    handleLoadMore={handleLoadMore}
                    handleChangeSortByPrice={handleChangeSortByPrice}
                    handleChangePriceRange={handleChangePriceRange}
                    handleFilterRate={handleFilterRate}
                    handleChangePage={handleChangePage}
                />
            ) : (
                <>
                    <div className="products-filter-mobile">
                        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                            <Button>
                                Lọc theo giá cả <DownOutlined />
                            </Button>
                        </Dropdown>
                        <span onClick={handleClickFilter}>Bộ lọc </span>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                        {pagination.total ? (
                            <>
                                {isLoadingProduct ? (
                                    <ProductSkeletonList length={pagination.limit} />
                                ) : (
                                    <ProductList data={productList} />
                                )}
                            </>
                        ) : (
                            <NoDataMobile />
                        )}

                        {isLoadMore && <ProductSkeletonList length={pagination.limit} />}
                    </div>
                    {!isShowFilter && pagination.total !== productList?.length && (
                        <div className="d-flex justify-content-center pb-3">
                            <ButtonLoadMore onClick={handleLoadMore} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ProductsCategory;
