import { List } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ProductItem from "./ProductItem";

const ProductList = (props) => {
    const { data, md, lg, xl, xxl } = props;

    const renderProductItem = (item) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: ProductList.js:10 ~ renderProductItem ~ item:", item);
        return (
            <List.Item key={item.id} className="div-item-product flex-column">
                <Link to={`/${item?.type === "COMBO" ? "combo" : "san-pham"}/${item?.slug}`} className="link">
                    <ProductItem item={item} />
                </Link>
            </List.Item>
        );
    };

    return (
        <div className="demo-infinite-container">
            <List
                grid={{
                    gutter: 10,
                    xs: 2,
                    sm: 2,
                    md: md || 3,
                    lg: lg || 4,
                    xl: xl || 5,
                    xxl: xxl || 5,
                }}
                dataSource={data}
                renderItem={(item) => renderProductItem(item)}
            ></List>
        </div>
    );
};

export default ProductList;
