import { FacebookOutlined, InstagramOutlined, PhoneOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import postAPI from "src/api/postAPI";
import settingAPI from "src/api/settingAPI";
import appStoreImg from "src/assets/images/appstore.png";
import bct1Img from "src/assets/images/bo-cong-thuong-1.png";
import bct2Img from "src/assets/images/bo-cong-thuong-2.png";
import bct3Img from "src/assets/images/bo-cong-thuong-3.png";
import playStoreImg from "src/assets/images/playstore.png";
import qrcodeImg from "src/assets/images/qrcode.png";
import "../styles.scss";
import { handleClickToSendEmail } from "src/containers/Home/components/Support";

const FooterHomeWebsite = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [footerInfo, setFooterInfo] = useState([]);
    const [connectData, setConnectdata] = useState([]);
    const [aboutData, setAboutdata] = useState([]);
    const [supportData, setSupportdata] = useState([]);

    const getInfoFooter = async () => {
        try {
            const res = await settingAPI.getSetting({
                fields: '["$all"]',
                filter: '{"type":"WEB"}',
            });

            const newFooterInfo = res?.results?.objects?.rows;
            setFooterInfo(newFooterInfo);
            // console.log('newFooterInfo: ', newFooterInfo);
        } catch (error) {
            console.log(error);
        }
    };

    const getPostDataList = async () => {
        try {
            const res = await postAPI.getPost({
                fields: '["$all",{"category_post":["$all"]}]',
                filter: '{"$category_post.title$":{"$in":["ABOUT","CONNECT","SUPPORT"]}}',
            });

            const newPostList = res?.results?.objects?.rows;
            // setFooterInfo(newFooterInfo);
            const newConnectData = newPostList?.filter((item) => item?.category_post?.title === "CONNECT");
            const newAboutData = newPostList?.filter((item) => item?.category_post?.title === "ABOUT");
            const newSupportData = newPostList?.filter((item) => item?.category_post?.title === "SUPPORT");

            setConnectdata(newConnectData);
            setAboutdata(newAboutData);
            setSupportdata(newSupportData);
            // console.log(newPostList);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getInfoFooter();
        getPostDataList();
    }, []);

    return (
        <div style={{ backgroundColor: "#fff", padding: "46px 0", fontSize: "14px" }}>
            <div className="container footer-container">
                <Row gutter={16}>
                    <Col span={6}>
                        <p className="fw-bold">HỖ TRỢ KHÁCH HÀNG</p>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={handleClickToSendEmail}
                            className="mb-1 link-footer"
                        >
                            Gửi yêu cầu hỗ trợ
                        </div>
                        {supportData?.map(
                            (item) =>
                                item?.title !== "Gửi yêu cầu hỗ trợ" && (
                                    <Link to={`/news/${item?.slug}`} className="mb-1 link-footer" key={item.id}>
                                        {item?.title}
                                    </Link>
                                ),
                        )}
                    </Col>

                    <Col span={6}>
                        <p className="fw-bold">VỀ AHAPPI</p>
                        {aboutData?.map((item) => (
                            <Link to={`/news/${item?.slug}`} className="mb-1 link-footer" key={item.id}>
                                {item?.title}
                            </Link>
                        ))}
                    </Col>

                    <Col span={6}>
                        <p className="fw-bold">HỢP TÁC VÀ LIÊN KẾT</p>
                        {connectData?.map((item) => (
                            <Link to={`/news/${item?.slug}`} className="mb-1 link-footer" key={item.id}>
                                {item?.title}
                            </Link>
                        ))}
                    </Col>

                    <Col span={6}>
                        <p className="fw-bold">KẾT NỐI VỚI CHÚNG TÔI</p>
                        <p className="mb-1 fw-bold">
                            {footerInfo?.map((item) => item?.key === "COMPANY_NAME" && item?.value)}
                        </p>
                        <p className="mb-1">
                            {footerInfo?.map((item) => item?.key === "COMPANY_ADDRESS" && item?.value)}
                        </p>
                        {/* <p style={{ color: "#547DBF" }}>
                            <FacebookOutlined /> <InstagramOutlined /> <YoutubeOutlined />
                        </p> */}
                    </Col>
                </Row>

                <Row gutter={16} className="mt-4">
                    <Col span={6}>
                        <div className="d-flex">
                            <div className="div-phone-footer-website me-2">
                                <div>
                                    <PhoneOutlined style={{ fontSize: "15px" }} />
                                </div>
                            </div>

                            <div>
                                <p className="mb-1 fw-bold">
                                    Hotline: <span style={{ color: "#547DBF" }}>1900 4517</span>
                                </p>
                                <p className="mb-1 fw-bold">
                                    Email: <a href="/support">info@ahappi.com</a>
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col span={12} style={{ paddingRight: "100px" }}>
                        <div className="d-flex justify-content-between">
                            <strong>TẢI ỨNG DỤNG TRÊN ĐIỆN THOẠI</strong>
                            {/* <img src={qrcodeImg} width={69} height={69} alt="qrcodeImg" /> */}
                            <div className="flex-column" style={{ width: "90px", marginTop: "7px" }}>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.hitek.ahappi"
                                    target="_blank"
                                >
                                    <img src={playStoreImg} width={87} height={26} alt="playStoreImg" />
                                </a>
                                <a
                                    href="https://apps.apple.com/vn/app/ahappi-ecommerce/id1613280154?l=vi"
                                    target="_blank"
                                >
                                    <img src={appStoreImg} width={87} height={26} alt="appStoreImg" />
                                </a>
                            </div>
                        </div>
                    </Col>

                    <Col span={6} className="py-2">
                        {/* <div className="d-flex">
							<img src={bct2Img} width={44} height={44} className="me-4" alt="bct2Img" />
							<img src={bct3Img} width={35} height={35} className="my-1" alt="bct3Img" />
							<img src={bct1Img} width={80} height={28} className="my-2" alt="bct1Img" />
						</div> */}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FooterHomeWebsite;
