import { Skeleton } from "@material-ui/lab";
import React from "react";
import "../styles.scss";

function ShopSkeleton(props) {
	return (
		<div className="shop-skeleton-container">
			<div className="shop-skeleton-left-box">
				<Skeleton variant="circle" width={50} height={50} />
				<div className="info-seller">
					<Skeleton animation="wave" variant="text" width={200} />
					<Skeleton animation="wave" variant="text" width={120} />
				</div>
			</div>
			<div className="shop-skeleton-right-box"></div>
		</div>
	);
}

export default ShopSkeleton;
