import { combineReducers } from "redux";
import addressReducer from "./AddressReducer";
import cartReducer from "./CartReducer";
import orderReducer from "./OrderReducer";
import productsReducer from "./ProductReducer";
import userReducer from "./UserReducer";
import categoryReducer from "./CategoryReducer";

const rootReducer = combineReducers({
  product: productsReducer,
  user: userReducer,
  cartStore: cartReducer,
  addressStore: addressReducer,
  orderStore: orderReducer,
  categoryStore: categoryReducer,
});

export default rootReducer;
