import React, { memo, useState } from "react";
import HeaderMobile from "./components/HeaderMobile";
import HeaderWebsite from "./components/HeaderWebsite";

const Header = (props) => {
	const [scroll, setScroll] = useState(false);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll, true);
	// }, []);

	// const handleScroll = () => {
	// 	if (window.pageYOffset > 300) {
	// 		if (!scroll) setScroll(true);
	// 	}
	// 	if (window.pageYOffset < 100) {
	// 		setScroll(false);
	// 	}
	// };

	return (
		<div className="div-scroll-default-layout">
			{window.innerWidth >= 768 ? (
				<HeaderWebsite {...props} />
			) : (
				<HeaderMobile scroll={scroll} {...props} />
			)}
		</div>
	);
};

export default memo(Header);
