import {
	ADD_ORDER_ITEMS,
	GET_MY_STORE_LIST,
	REMOVE_ORDER_ITEMS_CHECKED,
	SET_FILTERS,
	SET_SEARCH_INPUT,
} from "src/redux/actions/ProductActions/types";

const initState = {
	filters: {
		price_gte: 0,
		price_lte: 10000000,
		star: 0,
		search: "",
	},
	orderItemsChecked: JSON.parse(sessionStorage.getItem("orderItemsChecked")) || [],
	myStoreList: "",
};

const productsReducer = (state = initState, action) => {
	switch (action.type) {
		// FILTER PRODUCT
		case SET_SEARCH_INPUT: {
			const newFilters = {
				...state.filters,
				search: action.payload,
			};

			return {
				...state,
				filters: newFilters,
			};
		}
		case SET_FILTERS: {
			const newFilters = {
				...state.filters,
				price_gte: action.payload.price_gte,
				price_lte: action.payload.price_lte,
				star: action.payload.star,
			};

			return {
				...state,
				filters: newFilters,
			};
		}

		case ADD_ORDER_ITEMS: {
			const newOrderItemsChecked = action.payload;

			sessionStorage.setItem("orderItemsChecked", JSON.stringify(newOrderItemsChecked));
			return {
				...state,
				orderItemsChecked: newOrderItemsChecked,
			};
		}

		case REMOVE_ORDER_ITEMS_CHECKED: {
			const newOrderItemsChecked = [];

			sessionStorage.removeItem("orderItemsChecked");
			return {
				...state,
				orderItemsChecked: newOrderItemsChecked,
			};
		}

		case GET_MY_STORE_LIST: {
			return {
				...state,
				myStoreList: action.payload,
			};
		}
		default:
			return state;
	}
};

export default productsReducer;
