import { REMOVE_PAYMENT_TYPE, SET_PAYMENT_TYPE } from './type';

export const setPaymentTypeStore = (payload) => ({
	type: SET_PAYMENT_TYPE,
	payload,
});

export const removePaymentTypeStore = () => ({
	type: REMOVE_PAYMENT_TYPE,
});
