import axiosClient from "../axiosClient";

const postAPI = {
	async getPost(params) {
		const url = `/post`;
		const resp = await axiosClient.get(url, { params });

		return resp;
	},
};

export default postAPI;
