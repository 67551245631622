import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import categoryIcon from '../../../assets/images/category.png';
import hIcon from '../../../assets/images/h-icon.png';
import homeIcon from '../../../assets/images/home.png';
import newsIcon from '../../../assets/images/news.png';
import userIcon from '../../../assets/images/user-profile.png';

const Footer = (props) => {
    const history = useHistory();
    const location = useLocation();

    return (
        <div className="div-footer-default-layout d-flex justify-content-between">
            <div
                className="div-icon-footer text-center"
                style={location.pathname === '/' ? { backgroundColor: '#FBBC12', color: '#fff' } : {}}
            >
                <img
                    src={homeIcon}
                    width={20}
                    height={20}
                    onClick={() => history.push('/')}
                    style={
                        location.pathname === '/'
                            ? {
                                  filter: 'brightness(0) saturate(100%) invert(99%) sepia(100%)  hue-rotate(258deg) contrast(100%)',
                              }
                            : {}
                    }
                    alt="homeIcon"
                />
            </div>
            <div
                className="div-icon-footer text-center"
                style={location.pathname === '/danh-muc' ? { backgroundColor: '#FBBC12', color: '#fff' } : {}}
            >
                <img
                    src={categoryIcon}
                    width={20}
                    height={20}
                    onClick={() => history.push('/danh-muc')}
                    style={
                        location.pathname === '/danh-muc'
                            ? {
                                  filter: 'brightness(0) saturate(100%) invert(99%) sepia(100%)  hue-rotate(258deg) contrast(100%)',
                              }
                            : {}
                    }
                    alt="categoryIcon"
                />
            </div>
            <div
                className="div-icon-footer text-center"
                style={location.pathname === '/collaborators' ? { backgroundColor: '#FBBC12', color: '#fff' } : {}}
            >
                <img
                    src={hIcon}
                    width={25}
                    height={25}
                    onClick={() => history.push('/collaborators')}
                    style={
                        location.pathname === '/collaborators'
                            ? {
                                  filter: 'brightness(0) saturate(100%) invert(99%) sepia(100%)  hue-rotate(258deg) contrast(100%)',
                              }
                            : {}
                    }
                    alt="hIcon"
                />
            </div>
            <div
                className="div-icon-footer text-center"
                style={location.pathname === '/news' ? { backgroundColor: '#FBBC12', color: '#fff' } : {}}
            >
                <img
                    src={newsIcon}
                    width={20}
                    height={20}
                    onClick={() => history.push('/news')}
                    style={
                        location.pathname === '/news'
                            ? {
                                  filter: 'brightness(0) saturate(100%) invert(99%) sepia(100%)  hue-rotate(258deg) contrast(100%)',
                              }
                            : {}
                    }
                    alt="newsIcon"
                />
            </div>
            <div
                className="div-icon-footer text-center"
                style={location.pathname === '/user' ? { backgroundColor: '#FBBC12', color: '#fff' } : {}}
            >
                <img
                    src={userIcon}
                    width={20}
                    height={20}
                    onClick={() => history.push('/user')}
                    style={
                        location.pathname === '/user'
                            ? {
                                  filter: 'brightness(0) saturate(100%) invert(99%) sepia(100%)  hue-rotate(258deg) contrast(100%)',
                              }
                            : {}
                    }
                    alt="userIcon"
                />
            </div>
        </div>
    );
};

export default Footer;
