import { Pagination, Skeleton } from "@material-ui/lab";
import { Breadcrumb, Button, Col, InputNumber, List, Row, Select, Space, Tag } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import classNames from "classnames";
import { default as React, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as CategoryAPI from "src/api/categoryAPI";
import NoDataDesktop from "src/components/NoData/NoDataDesktop";
import FilterDesktopSkeleTon from "src/components/Skeleton/FilterDesktopSkeleTon";
import ProductSkeletonList from "src/components/Skeleton/ProductSkeletonList";
import ProductList from "src/containers/Products/components/ProductList";
import "../styles.scss";

const rateList = [1, 2, 3, 4, 5];

const FilterDesktop = (props) => {
    const {
        data,
        pagination,
        queryParams,
        isLoadingProduct,
        handleChangeSortByPrice,
        handleChangePriceRange,
        handleFilterRate,
        handleChangePage,
    } = props;

    const history = useHistory();
    const { slugCate } = useParams();

    const idCate = useMemo(
        () => slugCate?.substring(slugCate?.length - process.env.REACT_APP_ID_LENGTH, slugCate?.length),
        [slugCate],
    );

    const [featuredCategoriresState, setFeaturedCategoriresState] = useState([]);
    const [sortPrice, setSortPrice] = useState("");
    const [activeId, setActiveId] = useState(idCate || "");
    const [activeCategory, setActiveCategory] = useState({});
    const [activeRate, setActiveRate] = useState(0);
    const [loadingCate, setLoadingCate] = useState(true);
    const [priceRangeState, setPriceRangeState] = useState({
        price_gte: Number.parseInt(queryParams?.gte) || 0,
        price_lte: Number.parseInt(queryParams?.lte) || 0,
    });

    const [inputPrice, setInputPrice] = useState({
        price_gte: 0,
        price_lte: 0,
    });

    const priceRangeTagList = [
        {
            title: "Dưới 40.000",
            price_gte: 0,
            price_lte: 40000,
        },
        {
            title: "Từ 40.000 đến 120.000",
            price_gte: 40000,
            price_lte: 120000,
        },
        {
            title: "Từ 120.000 đến 360.000",
            price_gte: 120000,
            price_lte: 360000,
        },
        {
            title: "Trên 360.000",
            price_gte: 360000,
            price_lte: 9999999999,
        },
    ];

    const getFeaturedCategories = async () => {
        setLoadingCate(true);
        try {
            const categoryRes = await CategoryAPI.getList({
                fields: ["$all", { sub_categories: ["$all"] }],
                limit: 999,
            });

            const categoryList = categoryRes.results.objects.rows;
            const categoryActive = categoryList.filter((item) => item.id === activeId);

            setFeaturedCategoriresState(categoryList);
            setActiveCategory(...categoryActive);
            setLoadingCate(false);
        } catch (error) {
            setLoadingCate(false);
        }
    };

    const handleSearchByCategory = (item) => {
        history.push(`/danh-muc/${item?.slug}`);
        setActiveCategory(item);

        setSortPrice("");
    };

    const handleChange = (value) => {
        setSortPrice(value);
        const data = {
            orderBy: "price",
            sort: value,
        };

        if (handleChangeSortByPrice) {
            handleChangeSortByPrice(data);
        }
    };

    const handleFilterInputPrice = () => {
        if (handleChangePriceRange) {
            handleChangePriceRange(inputPrice);
        }
    };

    const handleRate = (rate) => {
        if (rate === activeRate) {
            setActiveRate(0);
            if (handleFilterRate) {
                handleFilterRate(0);
            }
        } else {
            setActiveRate(rate);
            if (handleFilterRate) {
                handleFilterRate(rate);
            }
        }
    };

    const handleSetPriceRange = (item) => {
        setPriceRangeState((prevValues) => {
            if (prevValues.price_gte === item.price_gte && prevValues.price_lte === item.price_lte) {
                return {
                    ...prevValues,
                    price_gte: 0,
                    price_lte: 9999999999,
                };
            }

            return {
                ...prevValues,
                price_gte: item.price_gte,
                price_lte: item.price_lte,
            };
        });

        let newPriceRange = {};
        if (priceRangeState.price_gte === item.price_gte && priceRangeState.price_lte === item.price_lte) {
            newPriceRange = { price_gte: 0, price_lte: 9999999999 };
        } else {
            newPriceRange = {
                price_gte: item.price_gte,
                price_lte: item.price_lte,
            };
        }

        if (handleChangePriceRange) {
            handleChangePriceRange(newPriceRange);
        }
    };

    const inputHandler = (e) => {
        const { value, maxLength } = e.target;
        if (String(value).length >= maxLength) {
            e.preventDefault();
            return;
        }
    };

    const onChangeInputPriceGte = (value) => {
        setInputPrice({
            ...inputPrice,
            price_gte: value,
        });
    };

    const onChangeInputPriceLte = (value) => {
        setInputPrice({
            ...inputPrice,
            price_lte: value,
        });
    };

    useEffect(() => {
        getFeaturedCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setActiveId(idCate);

        if (featuredCategoriresState.length > 0) {
            const categoryActive = featuredCategoriresState.filter((item) => item.id === idCate);
            setActiveCategory(...categoryActive);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCate]);

    return (
        <div
            className="w-100 div-default-layout flex-column filter-desktop"
            style={{
                backgroundColor: "#f7f8fa",
                minHeight: "calc(100vh - 45px)",
                backgroundImage: "none",
            }}
        >
            <div className="container my-0">
                {loadingCate ? (
                    <div className="py-2 mt-1 header-product-categories-skeleton">
                        <Skeleton className="header-skeleton-item" animation="wave" variant="text" width={60} />
                        <span className="separator">|</span>
                        <Skeleton className="header-skeleton-item" animation="wave" variant="text" width={150} />
                    </div>
                ) : (
                    <Breadcrumb className="py-2 mt-1" separator="|">
                        <BreadcrumbItem>
                            <Link to={"/"}>Trang chủ</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{activeCategory.title}</BreadcrumbItem>
                    </Breadcrumb>
                )}

                <Row gutter={[16, 16]}>
                    {loadingCate ? (
                        <FilterDesktopSkeleTon />
                    ) : (
                        <Col span={7}>
                            <div className="div-bg-pd">
                                <strong>Danh mục sản phẩm</strong>
                                <p
                                    className="pointer mb-0"
                                    style={{
                                        padding: "8px 16px",
                                        borderBottom: "1px solid #f0f0f0",
                                        fontSize: "14px",
                                        fontVariant: "tabular-nums",
                                        lineHeight: "1.5715",
                                    }}
                                    onClick={() => {
                                        history.push(`/san-pham`);
                                    }}
                                >
                                    Tất cả
                                </p>
                                <List
                                    // className="mt-2"
                                    size="small"
                                    header={null}
                                    footer={null}
                                    dataSource={featuredCategoriresState}
                                    renderItem={(item) => (
                                        <List.Item onClick={() => handleSearchByCategory(item)}>
                                            <p
                                                className="pointer mb-0"
                                                style={activeId === item?.id ? { fontWeight: "bold" } : {}}
                                            >
                                                {item?.title}
                                            </p>
                                        </List.Item>
                                    )}
                                />
                                <p
                                    className="pointer mb-0"
                                    style={{
                                        padding: "8px 16px",
                                        borderTop: "1px solid #f0f0f0",
                                        fontSize: "14px",
                                        fontVariant: "tabular-nums",
                                        lineHeight: "1.5715",
                                    }}
                                    onClick={() => {
                                        history.push(`/san-pham?type=COMBO`);
                                    }}
                                >
                                    Combo
                                </p>
                            </div>

                            {/* <div className="div-bg-pd">
							<strong>Thương hiệu</strong>
							<Select defaultValue="All brands" className="w-100 mt-2">
								<Select.Option value="All brands">All brands</Select.Option>
							</Select>
						</div> */}

                            <div className="div-bg-pd">
                                <strong>Lọc theo giá cả</strong>
                                <Select
                                    defaultValue={queryParams?.sort || sortPrice}
                                    className="w-100 mt-2"
                                    onChange={handleChange}
                                >
                                    <Select.Option value="asc">Thấp đến cao</Select.Option>
                                    <Select.Option value="desc">Cao đến thấp</Select.Option>
                                </Select>
                            </div>

                            <div className="div-bg-pd mb-2">
                                <strong>Khoảng giá</strong>
                                <div>
                                    <Space
                                        direction="vertical"
                                        size={[16, 8]}
                                        wrap
                                        className="mt-2 group-button-rate-6x4g"
                                    >
                                        {priceRangeTagList.map((item, index) => (
                                            <Tag
                                                key={index}
                                                className="tag-price-range"
                                                color={
                                                    item.price_gte === priceRangeState.price_gte &&
                                                    item.price_lte === priceRangeState.price_lte
                                                        ? "green"
                                                        : ""
                                                }
                                                onClick={() => handleSetPriceRange(item)}
                                            >
                                                {item.title}
                                            </Tag>
                                        ))}
                                    </Space>
                                    <p className="price-range-text">Chọn khoảng giá</p>

                                    <div className="my-2 d-flex">
                                        <InputNumber
                                            placeholder="Tối thiểu"
                                            className="w-100"
                                            size="large"
                                            type="number"
                                            step={10000}
                                            value={inputPrice.price_gte}
                                            onChange={onChangeInputPriceGte}
                                            min={0}
                                        />
                                        <span className="input-strikethrough"> - </span>
                                        <InputNumber
                                            placeholder="Tối thiểu"
                                            className="w-100"
                                            size="large"
                                            type="number"
                                            maxLength="11"
                                            onKeyPress={inputHandler}
                                            defaultValue={0}
                                            step={10000}
                                            value={inputPrice.price_lte}
                                            onChange={onChangeInputPriceLte}
                                            max={999999999}
                                        />
                                    </div>
                                    <Button
                                        disabled={
                                            inputPrice.price_gte >= inputPrice.price_lte ||
                                            inputPrice.price_gte === null ||
                                            inputPrice.price_lte === null
                                        }
                                        onClick={handleFilterInputPrice}
                                    >
                                        Áp dụng
                                    </Button>
                                </div>
                            </div>

                            <div className="div-bg-pd mb-2">
                                <strong>Đánh giá</strong>
                                <Space
                                    direction="horizontal"
                                    size={[16, 8]}
                                    wrap
                                    className="mt-2 group-button-rate-6x4g"
                                >
                                    {rateList.map((rate) => (
                                        <Button
                                            className={classNames("px-2", { "active-rate": activeRate === rate })}
                                            onClick={() => handleRate(rate)}
                                        >
                                            {rate} Sao
                                        </Button>
                                    ))}
                                </Space>
                            </div>
                        </Col>
                    )}

                    <Col span={17}>
                        <div className="div-bg-pd mb-2">
                            {pagination.total ? (
                                <>
                                    {isLoadingProduct ? (
                                        <ProductSkeletonList length={pagination.limit} md={2} lg={3} xl={4} xxl={4} />
                                    ) : (
                                        <ProductList data={data} md={2} lg={3} xl={4} xxl={4} />
                                    )}
                                </>
                            ) : (
                                <NoDataDesktop />
                            )}
                            {pagination.total !== data?.length && (
                                <div className="d-flex justify-content-center pb-3">
                                    <Pagination
                                        page={pagination.page}
                                        count={Math.ceil(pagination.total / pagination.limit)}
                                        onChange={handleChangePage}
                                        color="primary"
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FilterDesktop;
