import { Skeleton } from "@material-ui/lab";
import React from "react";

function SwiperCategoriesSkeleton({ length = 10 }) {
	const data = Array.from(Array(length).keys());

	return (
		<div className="swiper-categories-skeleton-container">
			{data.map((item) => (
				<div key={item} className="swiper-categories-skeleton-item">
					<Skeleton
						animation="wave"
						variant="rect"
						height={31}
						width={150}
						className="skelton-item"
					/>
				</div>
			))}
		</div>
	);
}

export default SwiperCategoriesSkeleton;
