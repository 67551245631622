import {
    BarChartOutlined,
    RightOutlined,
    LineChartOutlined,
    FileTextOutlined,
    DollarCircleOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Divider, Modal, Row, Tag } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import orderAPI from "src/api/orderAPI";
import bellImg from "src/assets/images/bell.png";
import cardImg from "src/assets/images/card.png";
import editImg from "src/assets/images/edit.png";
import heartImg from "src/assets/images/heart.png";
import homeImg from "src/assets/images/home.png";
import locationImg from "src/assets/images/location.png";
import logoAhappi from "src/assets/images/logo_ahappi2.png";
import policyImg from "src/assets/images/policy.png";
import settingImg from "src/assets/images/setting.png";
import userImg from "src/assets/images/user.png";
import HImg from "src/assets/images/h-icon.png";
import Header from "src/layouts/header/Header";
import { removeAddressCheckedStore } from "src/redux/actions/AddressActions";
import { removeCartStore } from "src/redux/actions/CartActions";
import { removePaymentTypeStore } from "src/redux/actions/OrderActions";
import { removeOrderItemsCheckedStore } from "src/redux/actions/ProductActions";
import { removeUserInfoStore } from "src/redux/actions/UserActions";
import { getNumberWithCommas } from "src/utils/numberUtils";
import FooterHomeMobile from "../../layouts/footer/components/FooterHomeMobile";
import OrderItem from "./OrderProduct/OrderItem";
import "./styles.scss";
import UserOrderSkeletonList from "./UserOrderSkeletonList";
import { convertTypeUser } from "src/utils/utils";

const User = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location?.pathname ?? "";

    const userInfo = useSelector((state) => state.user.userInfo);
    const userState = userInfo.object;

    const [isLoading, setIsLoading] = useState(true);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const [listOrder, setListOrder] = useState([]);
    const [waitingOrder, setWaitingOrder] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);

    const covertPathToString = (path) => {
        if (path.includes("/sales-register")) return "Đăng ký bán hàng qua ĐLKD";
        if (path.includes("/orderHistory")) return "Quản lý đơn hàng mua";
        if (path.includes("/orderProduct")) return "Quản lý đơn hàng bán";
        if (path.includes("/myProducts")) return "Sản phẩm của tôi";
        if (path.includes("/userInfo")) return "Thông tin cá nhân";
        if (path.includes("/thong-ke-hoa-hong")) return "Thống kê sử dụng gói hoa hồng";
        if (path.includes("/likeProducts")) return "Sản phẩm yêu thích";
        if (path.includes("/address")) return "Địa chỉ";
        if (path.includes("/news")) return "Thông báo & tin tức";
        if (path.includes("/policy")) return "Chính sách hỗ trợ";
        return "";
    };

    const getMyOrderList = async () => {
        setIsLoading(true);
        try {
            const myOrderList = await orderAPI.getListOrder({
                fields: '["$all",{"order_items":["$all",{"product":["$all"]}]},{"order_activitys":["activity","created_at_unix_timestamp"]}]',
                filter: `{"user_id":"${userState?.id}"}`,
                order: `[["created_at_unix_timestamp", "desc"]]`,
                limit: 2,
            });
            setListOrder(myOrderList.results.objects.rows);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getMyOrderWaiting = async () => {
        try {
            const res = await orderAPI.getMyListOrder({
                filter: `{"activity":"PROCESSING"}`,
            });

            const waitingOrderCount = res.results.objects.count;
            setWaitingOrder(waitingOrderCount);
        } catch (error) {}
    };

    const getTotalIncome = async () => {
        try {
            const params = {
                fields: '["$all",{"order_items":["$all"]} ]',
                order: '[["created_at", "desc"]]',
            };

            const data = {
                start_at: "01/01/2000",
                end_at: "31/12/3000",
            };

            const res = await orderAPI.getListOrderSeller(params, data);
            const totalIncomeRes = res.results.objects.total_sale;
            setTotalIncome(totalIncomeRes);
        } catch (error) {}
    };

    const logoutFunc = () => {
        dispatch(removeUserInfoStore());
        dispatch(removeCartStore());
        dispatch(removeAddressCheckedStore());
        dispatch(removeOrderItemsCheckedStore());
        dispatch(removePaymentTypeStore());
        history.push("/");
        window.location.reload();
    };

    useEffect(() => {
        if (window.innerWidth < 768) {
            getMyOrderList();
            getMyOrderWaiting();
            getTotalIncome();
        }

        if (window.innerWidth >= 768 && history.location.pathname === "/user") {
            // history.push("/sales-register");
            history.push("/user/main");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderUserMenu = () => {
        return (
            <>
                <div style={{ backgroundColor: "#fff", margin: "10px 0px", cursor: "pointer" }}>
                    {window.innerWidth < 768 && <div className="div-space" />}
                    {/* <div
                        onClick={() => history.push("/sales-register")}
                        className="py-3 px-2"
                        style={
                            pathname === "/sales-register" || pathname.includes("/order/")
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <img
                            src={editImg}
                            style={{ marginRight: "10px", width: "18px", objectFit: "contain" }}
                            alt="editImg"
                        />{" "}
                        Đăng ký bán hàng qua ĐLKD
                    </div>
                    <Divider className="my-0" /> */}
                    {userInfo?.object?.user_type === "USER" && (
                        <>
                            <div
                                onClick={() => history.push("/collaboratorsRegister")}
                                className="py-3 px-2"
                                style={
                                    pathname === "/collaboratorsRegister" || pathname.includes("/order/")
                                        ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                        : {}
                                }
                            >
                                <img
                                    src={editImg}
                                    style={{ marginRight: "10px", width: "18px", objectFit: "contain" }}
                                    alt="editImg"
                                />{" "}
                                Đăng ký trở thành ĐLKD
                            </div>
                            <Divider className="my-0" />
                            <div
                                onClick={() => history.push("/suppliersRegister")}
                                className="py-3 px-2"
                                style={
                                    pathname === "/suppliersRegister" || pathname.includes("/order/")
                                        ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                        : {}
                                }
                            >
                                <img
                                    src={editImg}
                                    style={{ marginRight: "10px", width: "18px", objectFit: "contain" }}
                                    alt="editImg"
                                />{" "}
                                Đăng ký trở thành NCC
                            </div>
                            <Divider className="my-0" />
                        </>
                    )}
                    {window.innerWidth >= 768 && (
                        <>
                            <div
                                onClick={() => history.push("/orderHistory")}
                                className="py-3 px-2"
                                style={
                                    pathname === "/orderHistory" || pathname.includes("/order/")
                                        ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                        : {}
                                }
                            >
                                <img src={cardImg} className="icon-img" alt="cardImg" /> Quản lý đơn hàng mua
                            </div>
                            {userState?.user_type === "SUPPLIER" && (
                                <>
                                    <Divider className="my-0" />
                                    <div
                                        onClick={() => history.push("/orderProduct")}
                                        className="py-3 px-2"
                                        style={
                                            pathname === "/orderProduct" || pathname.includes("/order/")
                                                ? {
                                                      backgroundColor: "#eaeff7",
                                                      color: "#547DBF",
                                                      border: " 1px solid #547DBF",
                                                  }
                                                : {}
                                        }
                                    >
                                        <img src={cardImg} className="icon-img" alt="cardImg" />
                                        Quản lý đơn hàng bán
                                    </div>
                                    <Divider className="my-0" />{" "}
                                </>
                            )}
                        </>
                    )}

                    {userState?.user_type === "SUPPLIER" && (
                        <div
                            onClick={() => history.push("/myProducts")}
                            className="py-3 px-2"
                            style={
                                pathname === "/myProducts" || pathname === "/addProduct" || pathname === "/addVoucher"
                                    ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                    : {}
                            }
                        >
                            <img src={homeImg} className="icon-img" alt="homeImg" /> Sản phẩm của tôi
                        </div>
                    )}

                    {window.innerWidth < 768 && userState?.user_type === "SUPPLIER" && (
                        <>
                            <Divider className="my-0" />
                            <div
                                onClick={() => history.push("/orderProduct")}
                                className="pt-3 px-2"
                                style={
                                    pathname === "/orderProduct" || pathname.includes("/order/")
                                        ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                        : {}
                                }
                            >
                                <img src={cardImg} className="icon-img" alt="cardImg" />
                                Quản lý đơn hàng bán
                            </div>
                        </>
                    )}
                </div>

                <div style={{ backgroundColor: "#fff", margin: "10px 0px", cursor: "pointer" }}>
                    {window.innerWidth < 768 && <div className="div-space" />}
                    <div
                        onClick={() => history.push("/userInfo")}
                        className="py-3 px-2"
                        style={
                            pathname === "/userInfo" || pathname === "/editUserInfo"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <img src={userImg} className="icon-img" alt="userImg" /> Thông tin cá nhân
                    </div>
                    <Divider className="my-0" />
                    <div
                        onClick={() => history.push(window.innerWidth > 768 ? "/userstatistics" : "/collaborators")}
                        className="py-3 px-2"
                        // style={
                        //     false ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" } : {}
                        // }
                    >
                        <img src={HImg} className="icon-img" alt="HImg" /> Quản lý tài sản
                    </div>
                    {/* <Divider className="my-0" />
                    <div
                        onClick={() => history.push("/thong-ke-hoa-hong")}
                        className="py-3 px-2"
                        style={
                            pathname === "/thong-ke-hoa-hong"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <BarChartOutlined style={{ marginRight: "10px", fontSize: "20px", color: "#777" }} /> Thống kê
                        sử dụng gói hoa hồng
                    </div> */}
                    {/* <Divider className="my-0" /> */}
                    {/* <div
                        onClick={() => history.push("/thong-ke-thu-nhap")}
                        className="py-3 px-2"
                        style={
                            pathname === "/thong-ke-thu-nhap"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <LineChartOutlined style={{ marginRight: "10px", fontSize: "20px", color: "#777" }} /> Thống kê
                        thu nhập
                    </div> */}
                    <Divider className="my-0" />
                    <div
                        onClick={() => history.push("/likeProducts")}
                        className="py-3 px-2"
                        style={
                            pathname === "/likeProducts"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <img src={heartImg} className="icon-img" alt="heartImg" /> Sản phẩm yêu thích
                    </div>
                    <Divider className="my-0" />
                    <div
                        onClick={() => history.push("/address")}
                        className="py-3 px-2"
                        style={
                            pathname === "/address"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <img src={locationImg} className="icon-img" alt="locationImg" /> Địa chỉ
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: "#fff",
                        margin: "10px 0px",
                        marginBottom: "25px",
                        cursor: "pointer",
                    }}
                >
                    {window.innerWidth >= 768 ? (
                        <div
                            onClick={() => history.push("/news")}
                            className="py-3 px-2"
                            style={
                                pathname === "/news" || pathname.includes("/news/")
                                    ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                    : {}
                            }
                        >
                            <img src={bellImg} className="icon-img" alt="bellImg" /> Thông báo & tin tức
                        </div>
                    ) : (
                        <>
                            <div className="div-space" />
                            <div onClick={() => history.push("/setting")} className="py-3 px-2">
                                <img src={settingImg} className="icon-img" alt="settingImg" /> Cài đặt
                            </div>
                        </>
                    )}
                    <Divider className="my-0" />
                    <div
                        onClick={() => history.push("/policy/supportpolicy")}
                        className="py-3 px-2"
                        style={
                            pathname === "/policy/"
                                ? { backgroundColor: "#eaeff7", color: "#547DBF", border: " 1px solid #547DBF" }
                                : {}
                        }
                    >
                        <img src={policyImg} className="icon-img" alt="policyImg" /> Chính sách hỗ trợ
                    </div>
                    {window.innerWidth < 768 && <div className="div-space" />}
                </div>

                {userState && (
                    <div style={{ padding: "0 12px", marginBottom: "50px" }}>
                        <Button
                            type="primary"
                            size="large"
                            className="w-100 mt-2 mb-4"
                            onClick={() => setOpenLogoutModal(true)}
                        >
                            Đăng xuất
                        </Button>
                    </div>
                )}
                {openLogoutModal && (
                    <Modal
                        visible={openLogoutModal}
                        onCancel={() => setOpenLogoutModal(false)}
                        footer={null}
                        title="Xác nhận"
                        className="text-center"
                        centered={true}
                    >
                        <p className="mb-5">Bạn muốn đăng xuất tài khoản?</p>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Button
                                    onClick={() => setOpenLogoutModal(false)}
                                    size="large"
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        color: "#517cbc",
                                        border: "1px solid #517cbc",
                                    }}
                                >
                                    Huỷ bỏ
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button onClick={logoutFunc} type="primary" size="large" style={{ width: "100%" }}>
                                    Đồng ý
                                </Button>
                            </Col>
                        </Row>
                    </Modal>
                )}
            </>
        );
    };

    return (
        <>
            {window.innerWidth >= 768 && <Header />}

            <div className="container mb-2 user-container">
                {window.innerWidth >= 768 && (
                    <Breadcrumb className="py-2 mt-1" separator="|">
                        <BreadcrumbItem>
                            <Link to="/" className="user-desktop-link">
                                Trang chủ
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to="/user" className="user-desktop-link">
                                Tài khoản
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{covertPathToString(pathname)}</BreadcrumbItem>
                    </Breadcrumb>
                )}

                <div
                    className="div-default-layout"
                    style={
                        window.innerWidth >= 768
                            ? { backgroundColor: "#f7f8fa", backgroundImage: "none", padding: 0, width: "100%" }
                            : {
                                  backgroundImage: "linear-gradient(94.59deg, #85DBE9 -0.44%, #C0F9EE 98.38%)",
                                  margin: "0 -12px",
                                  width: "100vw",
                              }
                    }
                >
                    <Row gutter={{ xs: 0, sm: 12, md: 12, lg: 12 }}>
                        <Col xs={24} md={8} lg={8}>
                            {userState ? (
                                <>
                                    <div
                                        className={
                                            window.innerWidth >= 768
                                                ? "flex-column py-4 text-center"
                                                : "d-flex pt-4 pb-3"
                                        }
                                        style={window.innerWidth >= 768 ? { backgroundColor: "#fff", margin: "0" } : {}}
                                    >
                                        <img
                                            style={{ borderRadius: "50%", objectFit: "cover" }}
                                            width="64"
                                            height="64"
                                            src={userInfo.object.avatar || logoAhappi}
                                            alt="avatar"
                                        />
                                        <div className="ms-2">
                                            <p className="mb-2">
                                                {userState?.fullname || userState?.email || userState?.username}
                                            </p>
                                            <Tag color="green">{convertTypeUser(userState?.user_type)}</Tag>
                                        </div>
                                    </div>
                                    {window.innerWidth < 768 && (
                                        <Row gutter={16} className="pb-4 statistics-uer-card-box">
                                            <Col span={12}>
                                                <Link to={`/orderProduct`} className="user-desktop-link">
                                                    <Card
                                                        size="small"
                                                        title="Số lượng đơn hàng cần xử lý"
                                                        className="total-product-card w-100"
                                                    >
                                                        <div className="body-box">
                                                            <span className="progress_number">{waitingOrder}</span>
                                                            <FileTextOutlined className="icon" />
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </Col>
                                            <Col span={12}>
                                                <Link to={`/thong-ke-thu-nhap`} className="user-desktop-link">
                                                    <Card
                                                        size="small"
                                                        title="Tổng thu nhập"
                                                        className="total-income-card w-100"
                                                    >
                                                        <div className="body-box">
                                                            <span className="progress_number">
                                                                {getNumberWithCommas(totalIncome) || 0}đ
                                                            </span>
                                                            <DollarCircleOutlined className="icon" />
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            ) : (
                                <div
                                    className="py-4 text-center"
                                    style={window.innerWidth >= 768 ? { backgroundColor: "#fff", margin: "0" } : {}}
                                >
                                    <h4 className="text-center">Chào mừng bạn đến với Ahappi!</h4>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="mt-2"
                                        style={{ width: window.innerWidth >= 768 ? "250px" : "100%" }}
                                        onClick={() => history.push("/login")}
                                    >
                                        Đăng nhập/Tạo tài khoản
                                    </Button>
                                </div>
                            )}

                            <div
                                style={
                                    window.innerWidth >= 768
                                        ? {
                                              margin: "0",
                                          }
                                        : {
                                              height: "calc(100vh - 100px)",
                                              margin: "0 -12px",
                                              backgroundColor: "#EEEEEF",
                                              borderTopLeftRadius: 20,
                                              borderTopRightRadius: 20,
                                              overflowY: "scroll",
                                          }
                                }
                            >
                                {window.innerWidth >= 768 ? (
                                    renderUserMenu()
                                ) : (
                                    <div className="content-border-default-layout w-100 order-management-box">
                                        <div
                                            className="d-flex justify-content-between"
                                            onClick={() => history.push("/orderHistory")}
                                        >
                                            <strong>Quản lý đơn hàng</strong>
                                            <span className="text-grey-login">
                                                Lịch sử <RightOutlined />
                                            </span>
                                        </div>
                                        {userState ? (
                                            <>
                                                <Divider className="my-2" />
                                                {isLoading ? (
                                                    <UserOrderSkeletonList length={2} />
                                                ) : (
                                                    listOrder &&
                                                    listOrder.length > 0 &&
                                                    listOrder.map((item, index) => (
                                                        <React.Fragment key={item?.id}>
                                                            <OrderItem item={item} />

                                                            <div
                                                                className={classNames("my-2", {
                                                                    line: listOrder?.length - 1 !== index,
                                                                })}
                                                            />
                                                        </React.Fragment>
                                                    ))
                                                )}
                                            </>
                                        ) : (
                                            <p className="pt-3">Đăng nhập và đặt hàng để theo dõi đơn hàng của bạn</p>
                                        )}
                                        {renderUserMenu()}
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col xs={0} md={16} lg={16}>
                            {props.children}
                        </Col>
                    </Row>
                    {window.innerWidth < 768 && <FooterHomeMobile />}
                </div>
            </div>
        </>
    );
};

export default User;
