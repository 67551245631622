import axiosClient from "../axiosClient";

const authAPI = {
	async loginWithGoogle(data) {
		const url = `auth/login_with_google`;
		const resp = await axiosClient.post(url, data);

		return resp;
	},

	async loginWithFacebook(data) {
		const url = `auth/login_with_facebook`;
		const resp = await axiosClient.post(url, data);

		return resp;
	},

	async loginWithPhone(data) {
		const url = `auth/login_with_phone`;
		const resp = await axiosClient.post(url, data);

		return resp;
	},
};

export default authAPI;
