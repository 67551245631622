import axiosClient from "../axiosClient";
const HOSTNAME = process.env.REACT_APP_API;
const table = "product";
const comboAPI = {
    findAll: ({
        page = 1,
        fields = ["$all", { product_items: ["$all", { product: ["$all"] }] }],
        limit = 100,
        filter = { type: "COMBO" },
    }) => {
        const url = `${HOSTNAME}/${table}?fields=${JSON.stringify(
            fields,
        )}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]&filter=${JSON.stringify(filter)}`;
        return axiosClient.get(url);
    },
    findOne: (id) => {
        const url = `${HOSTNAME}/${table}/${id}?fields=["$all",{"product_items":["$all",{"product": ["$all"]}]}]`;
        return axiosClient.get(url);
    },
};
export default comboAPI;
