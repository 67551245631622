import {
    EditOutlined,
    FieldTimeOutlined,
    ProfileOutlined,
    RightOutlined,
    TransactionOutlined,
    UsergroupAddOutlined,
    DollarCircleOutlined,
    MoneyCollectOutlined,
    EuroCircleOutlined,
    FileProtectOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Divider, Modal, Row } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getNumberWithCommas } from "src/utils/numberUtils";
import FooterHomeMobile from "../../layouts/footer/components/FooterHomeMobile";
import Header from "../../layouts/header/Header";
import CollabMain from "./components/CollabMain";
import "./styles.scss";
import ModalDeposit from "./ModalDeposit";

const Collaborators = ({ ...props }) => {
    // console.log("⛄❄️ TrieuNinhHan ~ Collaborators ~ props:", props);
    const { type } = props;
    const history = useHistory();
    const location = useLocation();
    const pathname = location?.pathname ?? "";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userStore = useSelector((state) => state.user);
    const userInfo = userStore?.userInfo?.object;
    // console.log("⛄❄️ TrieuNinhHan ~ Collaborators ~ userInfo:", userInfo);
    const id = userInfo?.id;

    const covertPathToString = (path) => {
        if (path.includes("/teamList")) return "Danh sách đội nhóm";
        if (path.includes("/referralCode")) return "Mã giới thiệu";
        if (path.includes("/ordersSold")) return "Thống kê hoa hồng";
        if (path.includes("/withdrawMoney")) return "Rút tiền về tài khoản";
        if (path.includes("/withdrawMoneyHistory")) return "Lịch sử rút tiền";
        if (path.includes("/transMoney")) return "Chuyển tiền về Ví tiêu dùng";
        if (path.includes("/userstatistics")) return "Thống kê người dùng";
        return "";
    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const [waitingOrder, setWaitingOrder] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);

    return (
        <div className="div-default-layout">
            <Header haveFilterInput={true} title="Đại lý kinh doanh" notBackIcon={true} />

            <div className="container collab-detail-desktop-container">
                {window.innerWidth >= 768 && (
                    <Breadcrumb className="pt-3" separator="|">
                        <BreadcrumbItem>
                            <Link to="/" className="collab-detail-desktop-link">
                                Trang chủ
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {type !== "SUPPLIER" ? (
                                <Link to="/collaborators" className="collab-detail-desktop-link">
                                    Đại lý kinh doanh
                                </Link>
                            ) : (
                                <Link to="/collaborators" className="collab-detail-desktop-link">
                                    Nhà cung cấp
                                </Link>
                            )}
                        </BreadcrumbItem>
                        <BreadcrumbItem>{covertPathToString(pathname)}</BreadcrumbItem>
                    </Breadcrumb>
                )}

                <div
                    style={{
                        margin: "10px -12px 16px -12px",
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={10} lg={8}>
                            <div className="div-bg-pd text-center w-100">
                                <p className="text-grey-login mb-1">Ví tiêu dùng</p>
                                <h2 className="fw-bold">
                                    <span style={{ fontSize: "30px" }}>
                                        {userInfo?.wallet?.toLocaleString("en-US")} VNĐ
                                    </span>
                                </h2>
                                {/* <p className="text-grey-login mb-1">(Tương đương 100.000.000đ)</p> */}
                                {window.innerWidth < 768 && (
                                    <>
                                        <Row gutter={16} className="pb-2 statistics-uer-card-box">
                                            <Col span={12}>
                                                <Link to={`/ordersSold`} className="user-desktop-link">
                                                    <Card
                                                        size="small"
                                                        title="Hoa hồng tuyến dưới"
                                                        className="total-product-card w-100"
                                                    >
                                                        <div className="body-box">
                                                            <span className="progress_number">
                                                                {getNumberWithCommas(userInfo?.total_bonus) || 0}đ
                                                            </span>
                                                            <DollarCircleOutlined className="icon" />
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </Col>
                                            <Col span={12}>
                                                <Link to={`/ordersSold`} className="user-desktop-link">
                                                    <Card
                                                        size="small"
                                                        title="Tài sản"
                                                        className="total-product-card total-product-card-v2 w-100"
                                                    >
                                                        <div className="body-box">
                                                            <span className="progress_number">
                                                                {getNumberWithCommas(
                                                                    userInfo?.total_bonus_by_supplier,
                                                                ) || 0}
                                                                đ
                                                            </span>
                                                            <DollarCircleOutlined className="icon" />
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </Col>
                                        </Row>
                                        {userInfo?.user_type === "BUSINESS_AGENT" && (
                                            <Row gutter={16} className="pb-2 statistics-uer-card-box">
                                                <Col span={24}>
                                                    <Link
                                                        to={`/teamList/${userInfo?.id}`}
                                                        className="user-desktop-link"
                                                    >
                                                        <Card
                                                            size="small"
                                                            title="Tổng thành viên"
                                                            className="total-income-card w-100"
                                                        >
                                                            <div className="body-box">
                                                                <span className="progress_number">
                                                                    {userInfo?.total_member || 0}
                                                                </span>
                                                                <UsergroupAddOutlined className="icon" />
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )}
                                {userInfo?.user_type === "BUSINESS_AGENT" && (
                                    <>
                                        <Button
                                            size="large"
                                            style={{
                                                margin: "8px",
                                                width: window.innerWidth >= 768 ? "250px" : "300px",
                                                height: "48px",
                                                borderRadius: "10px",
                                                color: "#517cbc",
                                                border: "1px solid #517cbc",
                                            }}
                                            onClick={() => history.push(`/teamList/${id}`)}
                                        >
                                            Xem danh sách đội nhóm
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{
                                                margin: "8px",
                                                width: window.innerWidth >= 768 ? "250px" : "300px",
                                                height: "48px",
                                            }}
                                            onClick={() => history.push("/referralCode")}
                                        >
                                            Mã giới thiệu
                                        </Button>
                                    </>
                                )}
                            </div>

                            <div className="div-bg-pd w-100 p-0">
                                {userInfo?.user_type === "USER" && (
                                    <>
                                        <Divider className="my-0" />
                                        <div
                                            className="d-flex justify-content-between py-3 px-2"
                                            onClick={() => history.push("/collaboratorsRegister")}
                                            style={
                                                pathname === "/collaboratorsRegister"
                                                    ? {
                                                          backgroundColor: "#eaeff7",
                                                          color: "#547DBF",
                                                          border: " 1px solid #547DBF",
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div className="d-flex">
                                                <EditOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                                <span className="ms-2">Đăng ký đại lý kinh doanh</span>
                                            </div>
                                            <RightOutlined style={{ paddingTop: "5px" }} />
                                        </div>
                                    </>
                                )}
                                {userInfo?.user_type === "USER" && (
                                    <>
                                        <Divider className="my-0" />
                                        <div
                                            className="d-flex justify-content-between py-3 px-2"
                                            onClick={() => history.push("/suppliersRegister")}
                                            style={
                                                pathname === "/suppliersRegister"
                                                    ? {
                                                          backgroundColor: "#eaeff7",
                                                          color: "#547DBF",
                                                          border: " 1px solid #547DBF",
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div className="d-flex">
                                                <EditOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                                <span className="ms-2">Đăng ký nhà cung cấp</span>
                                            </div>
                                            <RightOutlined style={{ paddingTop: "5px" }} />
                                        </div>
                                    </>
                                )}
                                <Divider className="my-0" />
                                {window.innerWidth >= 768 && (
                                    <>
                                        <div
                                            className="d-flex justify-content-between py-3 px-2"
                                            onClick={() => history.push("/userstatistics")}
                                            style={
                                                pathname === "/userstatistics"
                                                    ? {
                                                          backgroundColor: "#eaeff7",
                                                          color: "#547DBF",
                                                          border: " 1px solid #547DBF",
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div className="d-flex">
                                                <ProfileOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                                <span className="ms-2">Thống kê người dùng</span>
                                            </div>
                                            <RightOutlined style={{ paddingTop: "5px" }} />
                                        </div>
                                        <Divider className="my-0" />
                                    </>
                                )}
                                <div
                                    className="d-flex justify-content-between py-3 px-2"
                                    onClick={() => history.push("/ordersSold")}
                                    style={
                                        pathname === "/ordersSold"
                                            ? {
                                                  backgroundColor: "#eaeff7",
                                                  color: "#547DBF",
                                                  border: " 1px solid #547DBF",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="d-flex">
                                        <ProfileOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Thống kê hoa hồng</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                                <Divider className="my-0" />
                                <div
                                    onClick={showModal}
                                    className="d-flex justify-content-between py-3 px-2"
                                    // onClick={() => history.push("/ordersSold")}
                                    // style={
                                    //     pathname === "/ordersSold"
                                    //         ? {
                                    //               backgroundColor: "#eaeff7",
                                    //               color: "#547DBF",
                                    //               border: " 1px solid #547DBF",
                                    //           }
                                    //         : {}
                                    // }
                                >
                                    <div className="d-flex">
                                        <EuroCircleOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Nạp tiền</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                                <Divider className="my-0" />
                                <div
                                    className="d-flex justify-content-between py-3 px-2"
                                    onClick={() => history.push("/transMoney")}
                                    style={
                                        pathname === "/transMoney"
                                            ? {
                                                  backgroundColor: "#eaeff7",
                                                  color: "#547DBF",
                                                  border: " 1px solid #547DBF",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="d-flex">
                                        <MoneyCollectOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Chuyển tiền về Ví tiêu dùng</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                                <Divider className="my-0" />
                                <div
                                    className="d-flex justify-content-between py-3 px-2"
                                    onClick={() => history.push("/withdrawMoney")}
                                    style={
                                        pathname === "/withdrawMoney"
                                            ? {
                                                  backgroundColor: "#eaeff7",
                                                  color: "#547DBF",
                                                  border: " 1px solid #547DBF",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="d-flex">
                                        <TransactionOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Rút tiền về tài khoản</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                                <Divider className="my-0" />
                                <div
                                    className="d-flex justify-content-between py-3 px-2"
                                    onClick={() => history.push("/withdrawMoneyHistory")}
                                    style={
                                        pathname === "/withdrawMoneyHistory"
                                            ? {
                                                  backgroundColor: "#eaeff7",
                                                  color: "#547DBF",
                                                  border: " 1px solid #547DBF",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="d-flex">
                                        <FieldTimeOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Lịch sử rút tiền</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                                <Divider className="my-0" />
                                <div
                                    className="d-flex justify-content-between py-3 px-2"
                                    onClick={() => history.push("/policy-commission")}
                                    style={
                                        pathname === "/policy-commission"
                                            ? {
                                                  backgroundColor: "#eaeff7",
                                                  color: "#547DBF",
                                                  border: " 1px solid #547DBF",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="d-flex">
                                        <FileProtectOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                                        <span className="ms-2">Chính sách hoa hồng</span>
                                    </div>
                                    <RightOutlined style={{ paddingTop: "5px" }} />
                                </div>
                            </div>
                        </Col>

                        <Col xs={0} md={14} lg={16}>
                            {props.children || <CollabMain />}
                        </Col>
                    </Row>
                </div>
                {isModalOpen && (
                    <ModalDeposit
                        title="Nạp tiền"
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    ></ModalDeposit>
                )}
            </div>
            {window.innerWidth < 768 && <FooterHomeMobile />}
        </div>
    );
};

export default Collaborators;
