import { CheckCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { createContext } from "react";

const ModalContext = createContext();
const { confirm } = Modal;

function ModalProvider({ children }) {
	const showRedirectConfirm = (handleOk, title, okText) => {
		confirm({
			title: title || "Đăng nhập để tiếp tục mua hàng!",
			icon: <ExclamationCircleOutlined />,
			okText: okText || "Chuyển đến trang đăng nhập",
			okType: "danger",
			cancelText: "Hủy",
			centered: true,
			onOk() {
				handleOk();
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const showDeleteConfirm = (data, handleOk, title, okText) => {
		confirm({
			title: title || "Bạn có muốn xóa sản phẩm này không?",
			icon: <ExclamationCircleOutlined />,
			okText: okText || "Xóa",
			okType: "danger",
			cancelText: "Hủy",
			centered: true,
			onOk() {
				handleOk(data);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const showConfirm = (title, content, onOk) => {
		Modal.warning({
			title: title || "This is a warning message",
			content: content || null,
			centered: true,
			onOk: onOk || null,
		});
	};

	const showModalOrder = (handleOk, title, okText, okType, data, content) => {
		confirm({
			title: title || "Title modal!",
			icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
			okText: okText || "Xóa",
			okType: okType || "primary",
			cancelButtonProps: { style: { display: "none" } },
			content: content || null,
			centered: true,
			onOk() {
				handleOk(data);
			},
		});
	};

	const success = (title, onOk) => {
		Modal.success({
			title: title,
			centered: true,
			onOk: onOk || null,
		});
	};

	const warning = (title) => {
		Modal.warning({
			title: title,
		});
	};

	const error = (title, content) => {
		Modal.error({
			title: title,
			centered: true,
			content: content || null,
		});
	};
	const value = {
		showRedirectConfirm,
		showDeleteConfirm,
		showConfirm,
		showModalOrder,
		success,
		warning,
		error,
	};

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

export { ModalContext, ModalProvider };
