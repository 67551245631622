import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles.scss";

function NoDataDesktop({
	title = "Rất tiếc, không tìm thấy sản phẩm phù hợp với lựa chọn của bạn!",
}) {
	return (
		<div className="no-data-desktop-container">
			<ExclamationCircleFilled style={{ marginRight: "10px", marginLeft: "10px" }} />
			{title}
		</div>
	);
}

export default NoDataDesktop;
