import {
    ADD_PRODUCT_TO_CART,
    REMOVE_CART,
    REMOVE_CART_ITEM,
    REMOVE_MULTI_CART_ITEM,
    UPDATE_CART,
    RENEW_CART,
} from "src/redux/actions/CartActions/types";

const initState = {
    cart: JSON.parse(localStorage.getItem("cart")) || [],
};

const cartReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_TO_CART: {
            let newState = { ...state };
            const newItem = action.payload;
            const index = state.cart.findIndex((x) => x.id === action.payload.id);

            if (index >= 0) {
                // San pham da co trong gio hang
                newState.cart[index].quantity += newItem.quantity;

                if (!newState.cart[index].sharer || (newState.cart[index].sharer && newItem.sharer)) {
                    // San phẩm chưa có share_id thì sẽ gán share_id
                    // San phẩm có share_id và nhận share_id mới thì sẽ gán share_id mới
                    newState.cart[index].sharer = newItem.sharer;
                }
            } else {
                // San pham chua co trong gio hang
                newState.cart.push(newItem);
            }

            console.log("newItem: ", newItem);
            localStorage.setItem("cart", JSON.stringify(newState.cart));
            return newState;
        }

        case UPDATE_CART: {
            let newState = { ...state };
            const newItem = action.payload;
            const index = state.cart.findIndex((x) => x.id === action.payload.id);

            newState.cart[index] = newItem;

            localStorage.setItem("cart", JSON.stringify(newState.cart));
            return newState;
        }

        case REMOVE_CART_ITEM: {
            let newState = { ...state };
            const itemID = action.payload;
            const index = state.cart.findIndex((x) => x.id === itemID);

            newState.cart.splice(index, 1);

            localStorage.setItem("cart", JSON.stringify(newState.cart));
            return newState;
        }

        case REMOVE_MULTI_CART_ITEM: {
            let newState = { ...state };

            action.payload.forEach((item) => {
                let index = state.cart.findIndex((x) => x.id === item.id);
                newState.cart.splice(index, 1);
            });

            localStorage.setItem("cart", JSON.stringify(newState.cart));
            return newState;
        }

        case REMOVE_CART: {
            localStorage.removeItem("cart");
            return {
                ...state,
                cart: [],
            };
        }
        case RENEW_CART: {
            let newState = { ...state };
            newState.cart = [...(action.payload || [])];
            localStorage.removeItem("cart");
            localStorage.setItem("cart", JSON.stringify(newState.cart));
            return newState;
        }
        default:
            return state;
    }
};

export default cartReducer;
