import { convertParams, exportResults } from "src/utils";
import axiosClient from "../axiosClient";
import API from "axios";
const userInfo = localStorage.getItem("userInfo");
const accessToken = JSON.parse(userInfo);

const addressAPI = {
	async getUserAddress(params) {
		const url = `/address`;
		const resp = await axiosClient.get(url, { params });

		return resp;
	},

	async setAddressDefault(address) {
		const url = `/address/${address.id}`;
		const resp = await axiosClient.put(url, {
			is_default: !address.is_default,
		});

		return resp;
	},

	async removeAddress(address) {
		const url = `/address/${address.id}`;
		const resp = await axiosClient.delete(url);

		return resp;
	},

	async createNewAddress(address) {
		const url = `/address`;
		const resp = await axiosClient.post(url, address);

		return resp;
	},

	async findOne(id, params) {
		const url = `/address/${id}`;
		const resp = await axiosClient.get(url, { params });

		return resp;
	},

	async editAddress(id, newAddress) {
		const url = `/address/${id}`;
		const resp = await axiosClient.put(url, { ...newAddress });

		return resp;
	},
};

export const findOne = async (params) =>
	exportResults(
		await API.get(`/address?fields=["$all"]&filter={"is_default": true}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken?.token}`,
			},
		})
	);

export default addressAPI;
