import { Skeleton } from "@material-ui/lab";
import { Col, Row } from "antd";
import React from "react";

function OrdersSoldHeaderSkeleton() {
	return (
		<div className="orders-sold-header-skeleton-container">
			<Row gutter={16} className="row-container">
				<Col span={12} className="col-box">
					<Skeleton className="item" animation="wave" variant="rect" height={64} />
				</Col>
				<Col span={12} className="col-box">
					<Skeleton className="item" animation="wave" variant="rect" height={64} />
				</Col>
			</Row>
		</div>
	);
}

export default OrdersSoldHeaderSkeleton;
