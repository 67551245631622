import * as AddressAPI from "src/api/addressAPI";
import { GET_DEFAULT_ADDRESS, REMOVE_ADDRESS_CHECKED, SET_ADDRESS_CHECKED } from "./type";

// ADDRESS
export const setAddressCheckedStore = (payload) => ({
	type: SET_ADDRESS_CHECKED,
	payload,
});

export const removeAddressCheckedStore = () => ({
	type: REMOVE_ADDRESS_CHECKED,
});

export const getAddressDefault =
	(onSuccess = (success = "") => {}) =>
	async (dispatch) => {
		try {
			const res = await AddressAPI.findOne({
				fields: ["$all"],
			});
			dispatch({
				type: GET_DEFAULT_ADDRESS,
				payload: res?.results?.objects?.rows,
			});
			onSuccess(true);
		} catch (error) {
			console.log(error);
		}
	};
