import API from "axios";
import { exportResults, convertParams } from "src/utils";
import axiosClient from "../axiosClient";

const userInfo: any = localStorage.getItem("userInfo");
const accessToken = JSON.parse(userInfo);

const checkIssetToken = (token) => {
	console.log("token: ", token);
	if (token) {
		let headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken?.token}`,
		};
		return headers;
	}
};

export const getList = async (params) =>
	exportResults(
		await API.get("/product", {
			params: convertParams(params),
		})
	);

export const updateStatus = async (params) =>
	exportResults(
		await API.post(`/order_activity`, params, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken?.token}`,
			},
		})
	);

export const findOne = async (id, params, haventToken) =>
	exportResults(
		await API.get(`/product/${id}`, {
			params: convertParams(params),
			headers: !haventToken ? checkIssetToken(accessToken) : { "Content-Type": "application/json" },
		})
	);

export const findOneOrderItem = async (id, params) =>
	exportResults(
		await API.get(`/order_item/${id}`, {
			params: convertParams(params),
		})
	);

export const findOneOrderItemStatus = async (id, params) =>
	exportResults(
		await API.get(`/order/${id}`, {
			params: convertParams(params),
		})
	);

export const getSubList = async (params) =>
	exportResults(
		await API.get(`/product`, {
			params: convertParams(params),
		})
	);

export const searchProduct = async (params) => {
	const url = `/product/search`;
	const resp = await axiosClient.get(url, { params });
	return resp;
};

export const create = async (body, token) => exportResults(await API.post("/product", body, token));

export const update = async (id, body, token) =>
	exportResults(await API.put(`/product/${id}`, body, token));

export const remove = async (id) =>
	exportResults(
		await API.delete(`/product/${id}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken?.token}`,
			},
		})
	); // delte ko chịu

export const getOneProduct = async (id, params) => {
	const url = `/product/${id}`;
	const resp = await axiosClient.get(url, { params });
	return resp;
};

export const getProductList = async (params) => {
	const url = `/product`;
	const resp = await axiosClient.get(url, { params });
	return resp;
};

// Check rate
export const getOrderWithProduct = async (idProduct) => {
	const url = `/product/get_order_with_product/${idProduct}`;
	const resp = await axiosClient.get(url);
	return resp;
};

export const voteProduct = async (body, token) =>
	exportResults(
		await API.post("/vote", body, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken?.token}`,
			},
		})
	);
