import { ProductProps } from "src/constants/types";
/**
 * Export result from API
 * @param res
 */
import { USER_TYPE } from "../constants/index";

export const exportResults = (res: any) => res.data;

export const convertParams = (params: { [key: string]: any }) => {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)])
  );
};

export const convertPrice = (price: number) =>
  new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);

export const getIdProduct = (id: string) => {
  let temp = id.replace("sp", "");
  let tempArr = temp.split("-");
  return tempArr[tempArr.length - 1];
};

export const getRandom = (data: ProductProps[], n: number) => {
  // Shuffle array
  const shuffled = data?.sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  let selected = shuffled?.slice(0, n);
  return selected;
};

export const abbreviateNumber = (n: any) => {
  if (n < 1e3) return n;
  if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K+";
}
export const checkUserType = (type: string) => {
  if (type) {
    return USER_TYPE.COLLAP_USER;
  } else {
    return USER_TYPE.COLLAP_USER;
  }
};

export const accessTokenConfig = () => {
  const userInfo: any = localStorage.getItem("userInfo");
  const accessToken = JSON.parse(userInfo);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (accessToken?.token) {
    config.headers["Authorization"] = `Bearer ${accessToken?.token}`;
  }
  return config;
};

export const handlerScroll = () => {
  window.scrollTo(0, 0);
}
