import API from "axios";
import { exportResults, convertParams } from "src/utils";

export const getList = async (params) => {
    return exportResults(
        await API.get("/category", {
            params: convertParams(params),
        }),
    );
};
