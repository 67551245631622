import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';

const PrivateRoute = ({ children, ...rest }) => {
	const location = useLocation();
	const { isLoggedIn } = useContext(UserContext);

	return (
		// token
		isLoggedIn ? (
			<Route {...rest} />
		) : (
			<Redirect
				to={{
					pathname: '/login',
					state: { from: location },
				}}
			/>
		)
	);
};

export default PrivateRoute;
