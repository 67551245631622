import {
	LOGOUT_USER,
	REMOVE_ALL_SEARCH_HISTORY,
	REMOVE_USER_INFO,
	SET_IS_COLLAB,
	SET_SEARCH_HISTORY,
	SET_USER_INFO,
	UPDATE_NEW_INFO,
	UPDATE_PROFILE,
} from "src/redux/actions/UserActions/type";

const initState = {
	userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
	searchHistoryList: JSON.parse(localStorage.getItem("searchHistoryList")) || [],
};

const userReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_USER_INFO: {
			localStorage.setItem("userInfo", JSON.stringify(action.payload));
			const newUserInfo = action.payload;

			return {
				...state,
				userInfo: newUserInfo,
			};
		}

		case REMOVE_USER_INFO: {
			localStorage.removeItem("userInfo");
			return {
				...state,
				userInfo: {},
			};
		}

		case UPDATE_PROFILE:
			const token = state.userInfo.token;
			return {
				...state,
				userInfo: {
					token,
					object: action.payload,
				},
			};

		case LOGOUT_USER:
			localStorage.removeItem("userInfo");
			localStorage.removeItem("cart");
			return initState;

		case SET_IS_COLLAB: {
			const newUserInfo = {
				...state.userInfo,
			};
			newUserInfo.object.is_collab = true;

			const newState = {
				...state,
				userInfo: newUserInfo,
			};

			localStorage.setItem("userInfo", JSON.stringify(newState.userInfo));
			return { ...newState };
		}

		case SET_SEARCH_HISTORY: {
			const newSearchHistoryItem = action.payload;

			const newSearchHistoryList = [...state.searchHistoryList];

			if (newSearchHistoryList.length >= 2) {
				// Bỏ phần tử cuối cùng của mảng
				newSearchHistoryList.pop();
				// Chèn 1 phần tử vào vị trí đầu tiên
				newSearchHistoryList.unshift(newSearchHistoryItem);
			} else {
				newSearchHistoryList.unshift(newSearchHistoryItem);
			}

			localStorage.setItem("searchHistoryList", JSON.stringify(newSearchHistoryList));
			const newState = {
				...state,
				searchHistoryList: newSearchHistoryList,
			};

			return { ...newState };
		}

		case REMOVE_ALL_SEARCH_HISTORY: {
			localStorage.removeItem("searchHistoryList");
			const newState = {
				...state,
				searchHistoryList: [],
			};

			return { ...newState };
		}

		case UPDATE_NEW_INFO:
			const newUserInfo = {
				...state.userInfo,
			};

			newUserInfo.object = action.payload;

			const newState = {
				...state,
				userInfo: newUserInfo,
			};

			localStorage.setItem("userInfo", JSON.stringify(newState.userInfo));
			return { ...newState };

		default:
			return state;
	}
};

export default userReducer;
