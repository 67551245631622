import React, { useEffect, useState } from "react";
import HeaderWebsite from "src/layouts/header/components/HeaderWebsite";
import classes from "./ComboList.module.scss";
import {
    Link,
    useLocation,
    // , useHistory
} from "react-router-dom";
import comboApi from "src/api/comboApi";
import ComboItem from "./Component/ComboItem";
import { Input, Pagination } from "antd";
import { SearchOutlined } from "@material-ui/icons";

function ComboList() {
    const location = useLocation();
    // const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState();
    const LIMITED = 10;
    const [currentPage, setCurrentPage] = useState(null);
    const [pages, setPages] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const fetchAll = async () => {
        setLoading(true);
        try {
            const res = await comboApi.findAll({
                limit: LIMITED,
                page: currentPage,
                filter: { ...filter, type: "COMBO" },
            });
            if (res?.code === 200) {
                setPages(Math.ceil(res?.results?.objects?.count / LIMITED));
                console.log(
                    "⛄❄️ TrieuNinhHan ~ fetchAll ~ Math.ceil(res?.results?.objects?.count / LIMITED):",
                    Math.ceil(res?.results?.objects?.count / LIMITED),
                );
                const arr = res?.results?.objects?.rows?.map((item) => ({
                    id: item?.id,
                    name: item?.title,
                    image: item?.thumbnail,
                    list_image_item: item?.images,
                    price: item?.price?.toLocaleString(),
                    amount_sale: item?.amount_sale,
                    star: item?.star,
                    inventory_amount: item?.inventory_amount,
                }));
                console.log("⛄❄️ TrieuNinhHan ~ arr ~ arr:", arr);
                setItems(arr);
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ fetchAll ~ error:", error);
        }
        setLoading(false);
    };
    const onChangePage = (value) => {
        console.log("⛄❄️ TrieuNinhHan ~ onPageChange ~ a:", value);
        // history.push(`combo?page=${value}&filter=${params.get("filter")}`);
        setCurrentPage(value || 1);
    };
    const handleSearch = () => {
        // history.push(`combo?page=${1}&filter=${search}`);
        setFilter({
            title: { $iLike: `%25${search}%25` },
        });
        setCurrentPage(1);
    };
    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };
    useEffect(() => {
        console.log(currentPage, JSON.stringify(filter));
        if (currentPage) {
            fetchAll();
        }
    }, [currentPage, JSON.stringify(filter)]);
    useEffect(() => {
        setCurrentPage(parseInt(params.get("page")) || 1);
    }, [params.get("page")]);
    // useEffect(() => {
    //     if (params.get("filter")) {
    //         setFilter({
    //             title: { $iLike: `%25${params.get("filter")}%25` },
    //         });
    //         // setSearch(params.get("filter"));
    //     } else {
    //         setFilter({});
    //     }
    // }, [params.get("filter")]);

    return (
        <div>
            {/* <HeaderWebsite /> */}
            <div className={classes.contentContainer}>
                <div className={classes.navigate}>
                    <Link to="/" className={classes.link}>
                        Trang chủ |
                    </Link>{" "}
                    Danh sách Combo
                </div>
                <div style={{ padding: "0 15px", marginTop: 10, position: "relative" }}>
                    <Input
                        placeholder="Nhập tên combo"
                        style={{ borderRadius: 20 }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleOnKeyDown}
                    />
                    <SearchOutlined className={classes.searchOutlined} onClick={handleSearch} />
                </div>
                {items?.length > 0 || loading ? (
                    <div style={{ display: "flex", flexWrap: "wrap", padding: 10, overflowY: "auto", height: "60vh" }}>
                        {!loading
                            ? items?.map((item) => <ComboItem type="comboregister" item={item} key={item?.id} />)
                            : [1, 2, 3, 4, 5].map((item) => (
                                  <ComboItem type="comboregister" item={item} key={item} loading />
                              ))}
                    </div>
                ) : (
                    <div style={{ textAlign: "center", marginTop: 20 }}>Không có sản phẩm phù hợp</div>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: 20,
                        marginTop: 10,
                    }}
                >
                    {pages > 1 && (
                        <Pagination
                            total={pages}
                            pageSize={1}
                            defaultCurrent={1}
                            responsive
                            showSizeChanger={false}
                            onChange={onChangePage}
                            current={currentPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ComboList;
