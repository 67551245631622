import { Pagination } from "@material-ui/lab";
import classNames from "classnames";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import * as ProductAPI from "src/api/productAPI";
import userAPI from "src/api/userAPI";
import ButtonLoadMore from "src/components/Button/ButtonLoadMore";
import ProductSkeletonList from "src/components/Skeleton/ProductSkeletonList";
import Header from "src/layouts/header/Header";
import { handlerScroll } from "src/utils";
import ProductList from "../Products/components/ProductList";
import ShopHeader from "./Components/ShopHeader";
import ShopHeaderDesktop from "./Components/ShopHeaderDesktop";
import ShopSkeleton from "./Components/ShopSkeleton";
import "./styles.scss";

function ShopDetail(props) {
	const params = useParams();
	const idUser = params?.idUser;
	const location = useLocation();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [isLoadingUser, setIsLoadingUser] = useState(false);

	const [userProductList, setUserProductList] = useState([]);
	const [userTotalProduct, setUserTotalProduct] = useState(0);
	const [userInfo, setUserInfo] = useState({});
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
			limit: Number.parseInt(params.limit) || 10,
		};
	}, [location.search]);

	const getUserProducts = async () => {
		setIsLoading(true);
		try {
			const userProductRes = await ProductAPI.getSubList({
				fields: ["$all"],
				filter: { user_id: idUser },
				limit: queryParams.limit,
				page: queryParams.page,
			});
			const newUserProductObj = userProductRes?.results?.objects;

			const newPagination = {
				...pagination,
				page: userProductRes?.pagination?.current_page,
				total: userProductRes?.results?.objects?.count,
			};

			setUserProductList(newUserProductObj.rows);
			setUserTotalProduct(newUserProductObj.count);
			setPagination(newPagination);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getSellerInfo = async () => {
		setIsLoadingUser(true);
		try {
			const userInfoRes = await userAPI.findOne(idUser, {
				fields: '["$all"]',
			});
			const newUserInfo = userInfoRes?.results?.object;
			setUserInfo(newUserInfo);
			setIsLoadingUser(false);
		} catch (error) {
			setIsLoadingUser(false);
		}
	};

	const getProductLoadMore = async (page) => {
		setIsLoadMore(true);
		try {
			const productRes = await ProductAPI.getSubList({
				fields: ["$all"],
				filter: { user_id: idUser },
				limit: queryParams.limit,
				page: page,
			});

			const newData = userProductList.concat(productRes.results.objects.rows);
			setUserProductList(newData);
			setIsLoadMore(false);
		} catch (error) {
			setIsLoadMore(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		history.push({
			pathname: history.location.pathname,
			search: queryString.stringify(filters),
		});
	};

	const handleLoadMore = () => {
		setPagination({
			...pagination,
			page: pagination.page + 1,
		});
		getProductLoadMore(pagination.page + 1);
	};

	useEffect(() => {
		getUserProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams]);

	useEffect(() => {
		getSellerInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idUser]);

	useEffect(() => {
		handlerScroll();
	}, [history.location]);

	return (
		<div className="shop-detail-container">
			<div className="div-default-layout">
				<Header haveFilterInput={true} title="Thông tin cửa hàng" notBackIcon={true} />
			</div>

			<div className={classNames("shop-box", { container: window.innerWidth >= 768 })}>
				{isLoadingUser ? (
					<ShopSkeleton />
				) : window.innerWidth >= 768 ? (
					<ShopHeaderDesktop userInfo={userInfo} userTotalProduct={userTotalProduct} />
				) : (
					<ShopHeader userInfo={userInfo} userTotalProduct={userTotalProduct} />
				)}
			</div>

			<div className="container">
				<div className="d-flex justify-content-between title mt-3 mb-4">
					<strong>Tất cả sản phẩm</strong>
				</div>
				{isLoading ? (
					<ProductSkeletonList length={pagination.limit} />
				) : (
					<ProductList data={userProductList} />
				)}

				{isLoadMore && <ProductSkeletonList length={pagination.limit} />}

				{pagination.total !== userProductList?.length && (
					<div className="pagination-seller-store">
						{window.innerWidth >= 768 ? (
							<Pagination
								page={pagination.page}
								count={Math.ceil(pagination.total / pagination.limit)}
								onChange={handleChangePage}
								color="primary"
							/>
						) : (
							<ButtonLoadMore onClick={handleLoadMore} />
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default ShopDetail;
