import React from "react";
import Collaborators from "src/containers/Collaborators";
import CollabMain from "src/containers/Collaborators/components/CollabMain";

function InfoUser() {
    const Body = ({ type }) => {
        return <CollabMain />;
    };
    return (
        <>{window.innerWidth >= 768 ? <Collaborators children={Body({ type: "pc" })} /> : Body({ type: "mobile" })}</>
    );
}

export default InfoUser;
