import axiosClient from '../axiosClient';

const settingAPI = {
  async getSetting(params) {
    const url = `/setting`;
    const resp = await axiosClient.get(url, { params });

    return resp;
  },
};

export default settingAPI;
