import { Spin } from 'antd';
import classnames from 'classnames';
import React from 'react';

const LoadingComponent = ({ inline, text }) => {
	return (
		<div
			className={classnames(
				'loading-component text-center',
				inline && 'inline-loading d-flex justify-content-center align-items-center'
			)}
			style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}
		>
			<Spin size="large" tip={text || 'Đang tải...'} />
		</div>
	);
};

export default LoadingComponent;
