import { createSelector } from "@reduxjs/toolkit";

const cartItemsSelector = (state) => state.cartStore;

// Count number of products in cart
export const cartItemsCountSelector = createSelector(cartItemsSelector, (cartItems) => {
    // return cartItems.cart.reduce((count, item) => count + item.quantity, 0);
    return cartItems.cart.length || 0;
});

// Caculate total of cart
export const cartTotalSelector = createSelector(cartItemsSelector, (cartItems) => {
    // console.log("cartItems: ", cartItems);
    return cartItems.cart.reduce((total, item) => {
        if (item.price) {
            return total + item.price * item.quantity;
        } else {
            return total + item.fake_price * item.quantity;
        }
    }, 0);
});
