import axiosClient from "src/api/axiosClient";
import * as ProductAPI from "src/api/productAPI";
import { accessTokenConfig } from "src/utils";
import { ADD_ORDER_ITEMS, GET_MY_STORE_LIST, REMOVE_ORDER_ITEMS_CHECKED, SET_FILTERS, SET_SEARCH_INPUT } from "./types";

// FILTER
export const setSearchInputStore = (payload) => ({
    type: SET_SEARCH_INPUT,
    payload,
});

export const setFiltersStore = (payload) => ({
    type: SET_FILTERS,
    payload,
});

export const addOrderItemsStore = (payload) => ({
    type: ADD_ORDER_ITEMS,
    payload,
});

export const removeOrderItemsCheckedStore = () => ({
    type: REMOVE_ORDER_ITEMS_CHECKED,
});

export const getMyStoreList =
    (body, onSuccess = (success = false) => {}) =>
    async (dispatch) => {
        try {
            const res = await ProductAPI.getList({
                fields: ["$all"],
                filter: { user_id: body.user_id },
                limit: body.limit,
                page: body.page,
                order: [["created_at", "desc"]],
            });

            dispatch({
                type: GET_MY_STORE_LIST,
                payload: res?.results?.objects,
            });
            onSuccess(true);
        } catch (error) {
            onSuccess(true);
            console.log(error);
        }
    };

export const createProduct =
    (data, onSuccess = (success = false, err) => {}) =>
    async (getState) => {
        try {
            // if (data.image.length) {
            // 	// get image file
            // 	let elmImage = [];
            // 	for (let i = 0; i < data?.image?.length; i++) {
            // 		elmImage.push(data.image[i].originFileObj);
            // 	}

            // 	// handle image and thumbnail
            // 	let arrImage = [];
            // 	const formData = new FormData();
            // 	for (let i = 0; i < elmImage.length; i++) {
            // 		formData.set('image', elmImage[i]);
            // 		const resImageUpload = await axiosClient.post(
            // 			`/image/upload`,
            // 			formData,
            // 			accessTokenConfig(getState)
            // 		);
            // 		arrImage.push(resImageUpload?.results?.object.url);
            // 	}
            // 	console.log('Res image', arrImage)
            // 	data.image = arrImage;

            // 	formData.set('image', data?.thumbnail?.originFileObj);
            // 	const resThumbnail = await axiosClient.post(
            // 		`/image/upload`,
            // 		formData,
            // 		accessTokenConfig(getState)
            // 	);
            // 	console.log('Thum nail', resThumbnail)
            // 	data.thumbnail = resThumbnail?.results?.object.url;
            // }
            const res = await ProductAPI.create(data, accessTokenConfig(getState));
            console.log("RESPONE", res);
            if (res.code === 200) {
                onSuccess(true);
            }
        } catch (error) {
            onSuccess(false, error?.response?.data?.message);
            console.log(error);
        }
    };

// export const updateProduct =
//     (id, data, onSuccess = (success = false) => {}) =>
//     async (getState) => {
//         try {
//             const res = await ProductAPI.update(id, data, accessTokenConfig(getState));
//             if (res.code === 200) {
//                 onSuccess(true);
//             }
//         } catch (error) {
//             onSuccess(false);
//             console.log(error);
//         }
//     };
export const updateProduct =
    (id, data, onSuccess = (success = false, error = null) => {}) =>
    async (getState) => {
        try {
            const res = await ProductAPI.update(id, data, accessTokenConfig(getState));
            if (res.code === 200) {
                onSuccess(true, null); // Truyền null nếu không có lỗi
            }
        } catch (error) {
            onSuccess(false, error.response?.data?.message || "Cập nhật sản phẩm thất bại!"); // Truyền error nếu có lỗi
            console.log(error);
        }
    };

export const updateOrderStatus =
    (id, activity, onSuccess = (success = false) => {}, onError = (error = "") => {}) =>
    async (getState) => {
        try {
            const body = {
                order_id: id,
                activity,
            };
            const res = await ProductAPI.updateStatus(body, accessTokenConfig(getState));
            if (res.code === 200) {
                onSuccess(true);
            }
        } catch (err) {
            onError(err?.response);
            onSuccess(false);
        }
    };

export const getShopById =
    (id, onSuccess = (success = "") => {}) =>
    async (dispatch) => {
        try {
            const res = await ProductAPI.findOne(id, {
                fields: ["$all", { sub_category: ["$all"] }],
            });
            onSuccess(res?.results?.object);
        } catch (error) {
            console.log(error);
        }
    };
