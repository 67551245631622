import { Button, Form, InputNumber, Modal } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import userAPI from "src/api/userAPI";
import { UPDATE_PROFILE } from "src/redux/actions/UserActions/type";
import { ModalContext } from "src/contexts/ModalContext";
import "./styles.scss";
function ModalDeposit({ title, isModalOpen, setIsModalOpen }) {
    const modalContext = useContext(ModalContext);
    const [money, setMoney] = useState(1000000);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleOk = async ({ money = 1000000 }) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: ModalDeposit.js:15 ~ handleOk ~ money:", money);
        setLoading(true);
        try {
            if (handleconvert(money) > 1000000000000) {
                setLoading(false);
                return modalContext.error("Số tiền yêu cầu nạp không thể lớn hơn 1.000.000.000.000");
            }
            if (handleconvert(money) < 1000000) {
                setLoading(false);
                return modalContext.error("Số tiền yêu cầu nạp không thể nhỏ hơn 1.000.000");
            }
            const res = await userAPI.phakeMoney({ amount: handleconvert(money) });
            const res1 = await userAPI.getUserInfo();
            console.log("⛄❄️ TrieuNinhHan ~ handleOk ~ res:", res1);
            if (res?.code === 200 && res1?.code === 200) {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: res1?.results?.object,
                });
                modalContext.success("Yêu cầu nạp tiền thành công");
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ handleOk ~ error:", error);
            return modalContext.error(error?.message);
        }
        setLoading(false);
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const removeLeadingZeros = (input) => {
        let inputValue = input;
        const sanitizedValue = inputValue.replace(/[^\d]/g, "");
        const formattedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return formattedValue;
    };
    const handleOnChange = (value) => {
        const sanitizedValue = handleInputChange(value);

        setMoney(sanitizedValue);
    };
    const handleconvert = (string) => {
        return Number(string);
    };
    const handleInputChange = (text) => {
        let value = text;
        value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
        // Remove all non-numeric characters
        // let cleaned = ("" + text).replace(/\D/g, "");

        // // Split the string into an array of characters
        // let array = cleaned.split("");

        // // Insert a comma after every third digit from the right
        // for (let i = array.length - 3; i > 0; i -= 3) {
        //     array.splice(i, 0, ".");
        // }
        // return array.join("");

        // Join the array back into a string and set the state
    };
    const handlePriceChange = (value) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: ModalDeposit.js:77 ~ handlePriceChange ~ value:", value);
        setMoney(value);
    };
    useEffect(() => {
        form.setFieldsValue({
            money: 1000000,
        });
    }, []);
    useEffect(() => {
        console.log("⛄❄️ TrieuNinhHan ~ file: ModalDeposit.js:88 ~ useEffect ~ loading:", loading);
    }, [loading]);
    return (
        <Modal
            title={title}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Đồng ý"
            cancelText="Từ chối"
            okButtonProps={{ className: "custom-ok-button", disabled: loading || money < 1000000 }}
            className="anasdasd"
        >
            {/* <input
                ref={inputRef}
                style={{ width: "100%", marginBottom: 28 }}
                placeholder="Nhập số tiền"
                // min={0}
                value={money}
                onChange={(e) => handleOnChange(e.target.value)}
                // type="number"
                step={1}
                className="modalDepositInput"
                // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            /> */}
            <Form form={form} onFinish={handleOk} layout="vertical" autoComplete="off">
                <Form.Item name="money">
                    <InputNumber
                        placeholder="Nhập số tiền"
                        className="w-100"
                        size="large"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={handlePriceChange}
                        min={1000000}
                        max={1000000000000}
                    />
                </Form.Item>
                <Button
                    type="primary"
                    size="large"
                    style={{ width: "100%", borderRadius: "10px", border: "1px solid #517cbc" }}
                    htmlType="submit"
                >
                    Nạp tiền
                </Button>
            </Form>
        </Modal>
    );
}

export default ModalDeposit;
