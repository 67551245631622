import axiosClient from "src/api/axiosClient";
import { accessTokenConfig } from "src/utils";
import {
	LOGOUT_USER,
	REMOVE_ALL_SEARCH_HISTORY,
	REMOVE_USER_INFO,
	SET_IS_COLLAB,
	SET_SEARCH_HISTORY,
	SET_USER_INFO,
	UPDATE_NEW_INFO,
	UPDATE_PROFILE,
} from "./type";
// USER INFO

export const logout =
	(redirectCallback = () => {}) =>
	async (dispatch) => {
		try {
			dispatch({
				type: LOGOUT_USER,
			});
			redirectCallback();
		} catch (error) {
			console.log(error);
		}
	};

export const setUserInfoStore = (payload) => ({
	type: SET_USER_INFO,
	payload,
});

export const removeUserInfoStore = () => ({
	type: REMOVE_USER_INFO,
});

export const updateUserProfile =
	(data, id, onSuccess = (success = false) => {}) =>
	async (dispatch, getState) => {
		try {
			const res = await axiosClient.put(`/user/${id}`, data, accessTokenConfig());
			dispatch({
				type: UPDATE_PROFILE,
				payload: res?.results?.object,
			});
			const userInfo = localStorage.getItem("userInfo");
			const accessToken = JSON.parse(userInfo);
			const userProfile = {
				object: res?.results?.object,
				token: accessToken.token,
			};
			localStorage.setItem("userInfo", JSON.stringify(userProfile));
			onSuccess(true);
		} catch (error) {
			onSuccess(false);
		}
	};

export const updateProfileAvatar =
	(body, userId, onSuccess = (success = false) => {}) =>
	async (dispatch, getState) => {
		try {
			const formData = new FormData();
			formData.set("image", body.image);

			const res = await axiosClient.post(`/image/upload`, formData, accessTokenConfig(getState));
			const imageData = {
				avatar: res?.results?.object?.url,
			};

			if (imageData.avatar) {
				const res = await axiosClient.put(
					`/user/${userId}`,
					imageData,
					accessTokenConfig(getState)
				);
				dispatch({
					type: UPDATE_PROFILE,
					payload: res?.results?.object,
				});
				const userInfo = localStorage.getItem("userInfo");
				const accessToken = JSON.parse(userInfo);
				const userProfile = {
					object: res?.results?.object,
					token: accessToken.token,
				};
				localStorage.setItem("userInfo", JSON.stringify(userProfile));
			}

			onSuccess(true);
		} catch (error) {
			onSuccess(false);
			console.log(error);
		}
	};

export const setIsCollab = () => ({
	type: SET_IS_COLLAB,
});

export const setSearchHistoryStore = (payload) => ({
	type: SET_SEARCH_HISTORY,
	payload,
});

export const removeAllSearchHistoryStore = () => ({
	type: REMOVE_ALL_SEARCH_HISTORY,
});

export const updateNewUserInfo = (payload) => ({
	type: UPDATE_NEW_INFO,
	payload,
});
