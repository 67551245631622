import React, { useContext, useEffect, useState } from "react";
import Collaborators from "../index";
import classes from "./TransMoney.module.scss";
import { Button, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import userAPI from "src/api/userAPI";
import { ModalContext } from "src/contexts/ModalContext";
import Header from "src/layouts/header/Header";
import CurrencyInput from "react-currency-input";
import { UPDATE_PROFILE } from "src/redux/actions/UserActions/type";

//total_bonus: hoa hồng tuyến dưới
// total_bonus_by_supplier: hoa hồng giới thiệu
function TransMoney() {
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const [tab, setTab] = useState(1);
    const onChangeTab = (value) => {
        handleUpdateProfile();
        setTab(value);
        setMoney(0);
    };
    const handleUpdateProfile = async () => {
        try {
            const res1 = await userAPI.getUserInfo();
            console.log("⛄❄️ TrieuNinhHan ~ handleOk ~ res:", res1);
            if (res1?.code === 200) {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: res1?.results?.object,
                });
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:25 ~ handleUpdateProfile ~ error:", error);
        }
    };
    const [maxMoney, setMaxMoney] = useState();
    const [money, setMoney] = useState(0);
    const [loading, setLoading] = useState(false);
    const userStore = useSelector((state) => state.user?.userInfo?.object);
    // console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:40 ~ TransMoney ~ userStore:", userStore);
    const handleTransMoney = async () => {
        setLoading(true);
        try {
            const res = await userAPI.transferMoney({
                amount: Number(money?.split(",")?.join("")),
                wallet_type: tab === 1 ? "BONUS_POINT" : "BONUS_SUPPLIER",
            });
            if (res?.code === 200) {
                modalContext.success("Chuyển tiền thành công");
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:20 ~ handleTransMoney ~ error:", error);
            modalContext.error(error?.message);
        }
        setLoading(false);
    };
    const removeLeadingZeros = (input) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:41 ~ removeLeadingZeros ~ input:", input);
        let sanitizedValue = input.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Loại bỏ dấu chấm và dấu phẩy
        return sanitizedValue;
    };
    const handleOnChange = (value) => {
        console.log("🧑‍🏭 TrieuNinhHan ~ file: TransMoney.js:65 ~ handleOnChange ~ value:", value);
        const sanitizedValue = removeLeadingZeros(value);
        if (Number(sanitizedValue?.split(",")?.join("")) > Number(maxMoney)) {
            modalContext.error("Số tiền yêu cầu rút đang lớn hơn số tiền ở Ví");
        } else {
            setMoney(sanitizedValue);
        }
    };
    useEffect(() => {
        switch (tab) {
            case 1:
                setMaxMoney(userStore?.total_bonus);
                break;
            case 2:
                setMaxMoney(userStore?.total_bonus_by_supplier);
                break;
            default:
                break;
        }
    }, [tab]);
    const Body = ({ type }) => {
        return (
            <div className={classes.wrapper}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        {type === "mobile" && (
                            <Header
                                haveFilterInput={true}
                                title="Chuyển tiền về Ví tổng tài khoản"
                                linkGoback="/collaborators"
                            />
                        )}
                        <div className={classes.title}>
                            <h6
                                onClick={() => onChangeTab(1)}
                                className={tab === 1 ? classes.tab : classes.tabDontActive}
                                style={{ padding: "0 10px", borderRight: "1px solid black" }}
                            >
                                Chuyển từ Ví Hoa hồng tuyến dưới
                            </h6>
                            <h6
                                onClick={() => onChangeTab(2)}
                                className={tab === 2 ? classes.tab : classes.tabDontActive}
                                style={{ padding: "0 10px" }}
                            >
                                Chuyển từ Ví Tài sản
                            </h6>
                        </div>
                        <div className={classes.horizontal}></div>
                        <div className={classes.moneyContainer}>
                            <p>Số dư</p>
                            <h2 className={classes.money}>{Number(maxMoney).toLocaleString("en-US")} VNĐ</h2>
                        </div>
                    </div>
                    <div className={classes.bottom}>
                        <p>Thông tin chuyển tiền</p>
                        <input
                            style={{ width: "100%", marginBottom: 28 }}
                            placeholder="Nhập số tiền"
                            min={0}
                            value={money}
                            onChange={(e) => handleOnChange(e.target.value)}
                            type="text"
                            step={1}
                            className={classes.input}
                        />

                        <br />
                        <Button
                            disabled={loading || money <= 0}
                            type="primary"
                            size="large"
                            className="w-100"
                            onClick={handleTransMoney}
                        >
                            Chuyển tiền
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>{window.innerWidth >= 768 ? <Collaborators children={Body({ type: "pc" })} /> : Body({ type: "mobile" })}</>
    );
}

export default TransMoney;
