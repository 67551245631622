import React from "react";
import classes from "./ItemProduct.module.scss";
import logo from "src/assets/images/logo_ahappi.png";
import { Link } from "react-router-dom";
import { Divider, Rate } from "antd";
import soldOut from "src/assets/images/soldout.png";
function ComboItem({ item, loading = false, type = "home" }) {
    console.log("⛄❄️ TrieuNinhHan ~ ComboItem ~ item:", item);
    return (
        <div className={type === "home" ? classes.ComboItemWrapper : classes.ComboItemWrapperV2}>
            <div className={classes.ComboItemContainer}>
                {!loading ? (
                    <Link style={{ textDecoration: "none", color: "inherit" }} to={`/combo/${item?.id}`}>
                        <div className={classes.ComboItemImage}>
                            <img className={classes.ComboItemSrc} src={item?.image || logo} />
                            {item?.inventory_amount < 1 && (
                                <img className={classes.CombbSoldOut} src={soldOut} alt="sold out" />
                            )}
                        </div>
                        {/* <div className={classes.ComboListContainer}>
                            {item?.list_image_item?.map(
                                (item, index) =>
                                    index < 3 && (
                                        <div className={classes.ComboListImage}>
                                            <img key={item} className={classes.ComboListImageSrc} src={item || logo} />
                                        </div>
                                    ),
                            )}
                        </div> */}
                        <div className={classes.ComboInfo}>
                            <p className={classes.ComboTitle}>{item?.name}</p>
                            <div className="d-flex align-items-center">
                                <div style={{ marginTop: "-3px" }}>
                                    <Rate allowHalf disabled value={item?.star} style={{ fontSize: "12px" }} />
                                </div>
                                <Divider type="vertical" />
                                <span className="text-grey-login" style={{ fontSize: "12px" }}>
                                    Đã bán {item?.amount_sale}
                                </span>
                            </div>
                            <p className={classes.ComboPrice}>{item?.price} ₫ </p>
                        </div>
                    </Link>
                ) : (
                    <div style={{ background: "#f0f0f0", height: "100%" }}></div>
                )}
            </div>
        </div>
    );
}

export default React.memo(ComboItem);
