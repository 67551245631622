import { MessageFilled, PhoneFilled, MessageOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React, { useContext } from "react";
import shopCheck from "src/assets/images/shop-check.png";
import zaloLogo from "src/assets/images/zalo.png";
import logoAhappi from "src/assets/images/logo_ahappi2.png";
import { ModalContext } from "src/contexts/ModalContext";
import "../styles.scss";

function ShopHeader({ userInfo, userTotalProduct }) {
    const modalContext = useContext(ModalContext);

    const handleSendSmsZalo = () => {
        if (userInfo?.phone) {
            window.open(`https://zalo.me/${userInfo?.phone}`);
        } else {
            modalContext.showConfirm("Thông báo", "Người dùng này không có số điện thoại!");
        }
    };

    const handleSendSms = () => {
        if (userInfo?.phone) {
            window.open(`sms:${userInfo?.phone}`);
        } else {
            modalContext.showConfirm("Thông báo", "Người dùng này không có số điện thoại!");
        }
    };

    const handleCall = () => {
        if (userInfo?.phone) {
            window.open(`tel:${userInfo?.phone}`);
        } else {
            modalContext.showConfirm("Thông báo", "Người dùng này không có số điện thoại!");
        }
    };

    console.log(userInfo);

    return (
        <div
            className="seller-container"
            style={
                userInfo?.thumbnail
                    ? { backgroundImage: `url(${userInfo?.thumbnail})` }
                    : { backgroundImage: "linear-gradient(to right, rgb(133, 219, 233), rgb(192, 249, 238))" }
            }
        >
            <div className="seller-left-box">
                <div className="d-flex align-items-center">
                    <img
                        src={userInfo?.avatar || logoAhappi}
                        alt=""
                        className={classNames("avatar-seller", {
                            "avatar-desktop": window.innerWidth >= 768,
                        })}
                        style={{ objectFit: "cover" }}
                    />
                </div>
                <div className="seller-info">
                    <p className="seller-name">
                        {userInfo?.fullname}
                        {userInfo?.is_paid && <img src={shopCheck} className="shop-check-icon" alt="shop-check-icon" />}
                    </p>
                    <p className="title">Tổng sản phẩm: {userTotalProduct || 0}</p>
                    {userInfo?.phone && <p className="title">SĐT: {userInfo?.phone}</p>}
                    {/* <p className="title">Phản hồi Chat: 100%</p> */}
                </div>
            </div>
            <div className="seller-right-box">
                {window.innerWidth >= 768 ? (
                    <>
                        <button onClick={handleSendSms} className="button-send-message-desktop">
                            <MessageFilled className="icon-button-desktop" />
                            <span className="title">Nhắn tin</span>
                        </button>
                        <button onClick={handleSendSmsZalo} className="button-zalo-desktop">
                            <img src={zaloLogo} alt="zaloLogo" />
                            <span className="title">Nhắn tin</span>
                        </button>
                    </>
                ) : (
                    <>
                        <button onClick={handleCall} className="icon-button">
                            <PhoneFilled />
                        </button>
                        <button onClick={handleSendSms} className="icon-button">
                            <MessageFilled />
                        </button>
                        <button
                            onClick={handleSendSmsZalo}
                            href={`https://zalo.me/${userInfo?.phone}`}
                            className="icon-button-zalo"
                        >
                            <img src={zaloLogo} alt="zaloLogo" />
                        </button>
                    </>
                )}
            </div>
            <div className="seller-container-overlay"></div>
        </div>
    );
}

export default ShopHeader;
