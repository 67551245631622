import { Button } from "antd";
import classNames from "classnames";
import React from "react";
import "./styles.scss";

function ButtonLoadMore({ onClick, className = "" }) {
	return (
		<Button onClick={onClick} className={classNames("button-loadmore-component", className)}>
			Xem thêm
		</Button>
	);
}

export default ButtonLoadMore;
