import axiosClient from "../axiosClient";
import queryString from "query-string";

const orderAPI = {
	async createOrder(orderData) {
		const url = `/order`;
		const resp = await axiosClient.post(url, { ...orderData });
		return resp;
	},

	async getPreOrder(orderData) {
		const url = `/order/pre_order`;
		const resp = await axiosClient.post(url, { ...orderData });
		return resp;
	},
	async getListOrder(params) {
		const url = `/order`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `/order/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async getMyListOrder(params) {
		const url = `/order/get_list_with_token`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async getListHistoryBonus(pagination, data) {
		const url = `order_item/get_list_V2?fields=["$all",{"sharer":["$all"]},{"order":["$all"]}]&order=[["created_at", "desc"]]&filter={"$order.activity$":"COMPLETED","sharer_id":{"$not":null}}&limit=${
			pagination.limit || 5
		}&page=${pagination.page || 1}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async getListOrderSeller(params, data) {
		const queryPrams = queryString.stringify(params);

		const url = `/order/get_list_order_seller?${queryPrams}`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default orderAPI;
