export const convertTypeUser = (value) => {
    switch (value) {
        case "USER":
            return "Thành viên";
        case "SUPPLIER":
            return "Nhà cung cấp";
        case "BUSINESS_AGENT":
            return "Đại lý kinh doanh";
        default:
            return value;
    }
};
export const convertWalletType = (value) => {
    switch (value) {
        case "SELL_PRODUCT":
            return "Bán hàng";
        case "CONSUMER_MEMBER":
            return "Thành viên tiêu dùng";
        case "BONUS_POINT":
            return "Tuyến dưới";
        default:
            return "Giới thiệu";
    }
};
