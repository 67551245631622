import axiosClient from "../axiosClient";
const cartAPI = {
    async getAll({ filter = "{}", page = 1, limit = 100, fields = '["$all",{"product":["$all",{"user":["$all"]}]}]' }) {
        const url = `cart?fields=${fields}&filter=${filter}&limit=${limit}&page=${page}`;
        const resp = await axiosClient.get(url);
        return resp;
    },
    async addProduct(body) {
        const url = `cart`;
        const resp = await axiosClient.post(url, body);
        return resp;
    },
    async deleteOneQuantity(id) {
        const url = `cart/${id}`;
        const resp = await axiosClient.delete(url);
        return resp;
    },
    async checkDeleteProducts(body) {
        const url = `product/check_product_deleted`;
        const resp = await axiosClient.post(url, body);
        return resp;
    },
};

export default cartAPI;
