import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import CollabRoute from "src/components/CollabRoute";
import LoadingComponent from "src/components/LoadingComponent";
import PrivateRoute from "src/components/PrivateRoute";
import ProductsCategory from "src/containers/ProductsByCategory";
import Shop from "src/containers/Shop";
import ShopDetail from "src/containers/ShopDetail";
import BonusStatistical from "src/containers/User/BonusStatistical";
import FooterHomeWebsite from "./footer/components/FooterHomeWebsite";
import SupportPolicy from "src/containers/User/Policy/SupportPolicy";
import { useSelector } from "react-redux";
import ComboList from "src/containers/Combo/ComboList";
import TransMoney from "src/containers/Collaborators/components/TransMoney";
import InfoUser from "src/containers/User/InfoUser/InfoUser";

const Home = React.lazy(() => import("../containers/Home"));
const Categories = React.lazy(() => import("../containers/Categories"));
const Products = React.lazy(() => import("../containers/Products"));
const ProductDetail = React.lazy(() => import("../containers/ProductDetail"));
const ComboDetail = React.lazy(() => import("../containers/Combo/ComboDetail"));

const FilterProductsMobile = React.lazy(() => import("../containers/Products/components/FilterProductsMobile"));
const FilterCategoriesMobile = React.lazy(() =>
    import("../containers/ProductsByCategory/components/FilterCategoriesMobile"),
);
const Cart = React.lazy(() => import("../containers/Cart"));
const Order = React.lazy(() => import("../containers/Order"));
const Address = React.lazy(() => import("../containers/Order/components/Address"));
const Payment = React.lazy(() => import("../containers/Order/components/Payment"));
const OrderInfo = React.lazy(() => import("../containers/Order/components/OrderInfo"));
const Collaborators = React.lazy(() => import("../containers/Collaborators"));
const ReferralCode = React.lazy(() => import("../containers/Collaborators/components/ReferralCode"));
const TeamList = React.lazy(() => import("../containers/Collaborators/components/TeamList"));
const TeamListB = React.lazy(() => import("../containers/Collaborators/components/TeamListB"));

const SalesRegister = React.lazy(() => import("../containers/User/SalesRegister/index"));
const MainInfo = React.lazy(() => import("../containers/User/MainInfo/index"));
const CollaboratorsRegister = React.lazy(() => import("../containers/Collaborators/components/CollaboratorsRegister"));
const SuppliersRegister = React.lazy(() => import("../containers/Collaborators/components/SuppliersRegister"));

const OrdersSold = React.lazy(() => import("../containers/Collaborators/components/OrdersSold"));
const WithdrawMoney = React.lazy(() => import("../containers/Collaborators/components/WithdrawMoney"));
const WithdrawMoneyHistory = React.lazy(() => import("../containers/Collaborators/components/WithdrawMoneyHistory"));
const News = React.lazy(() => import("../containers/News"));
const NewsDetail = React.lazy(() => import("../containers/News/components/NewsDetail"));
const User = React.lazy(() => import("../containers/User"));
const MyProducts = React.lazy(() => import("../containers/User/MyProducts"));
const IncomeStatistics = React.lazy(() => import("../containers/User/IncomeStatistics"));
const LikeProducts = React.lazy(() => import("../containers/User/Favorite"));
const Setting = React.lazy(() => import("../containers/User/Setting"));
const OrderHistory = React.lazy(() => import("../containers/User/OrderHistory"));
const OrderHistoryDetail = React.lazy(() => import("../containers/User/OrderHistory/OrderHistoryDetail"));
const OrderProduct = React.lazy(() => import("../containers/User/OrderProduct"));
const OrderProductDetailStatus = React.lazy(() => import("../containers/User/OrderProduct/OrderProductDetail"));
const Policy = React.lazy(() => import("../containers/User/Policy/PolicyDetail"));
const UserInfo = React.lazy(() => import("../containers/User/UserInfo"));
const EditUserInfo = React.lazy(() => import("../containers/User/UserInfo/EditUserInfo"));
const AddProduct = React.lazy(() => import("../containers/User/MyProducts/AddProduct"));
const AddVoucher = React.lazy(() => import("../containers/User/MyProducts/AddVoucher"));
const OrderDetail = React.lazy(() => import("../containers/User/OrderProduct/OrderDetail"));
const Rules = React.lazy(() => import("src/containers/Rules"));
const Support = React.lazy(() => import("src/containers/Home/components/Support"));
const PolicyCommision = React.lazy(() => import("src/containers/User/Policy/PolicyCommision"));

const DefaultLayout = () => {
    const userStore = useSelector((state) => state.user);
    const [roleRouter, setRolerouter] = useState([]);
    const userInfo = userStore?.userInfo?.object;

    const routerSupplier = [
        {
            id: "myProducts",
            path: "/myProducts",
            component: MyProducts,
        },
        {
            id: "addProduct",
            path: "/addProduct",
            component: AddProduct,
        },
        {
            id: "addProduct/:id",
            path: "/addProduct/:id",
            component: AddProduct,
        },
    ];
    useEffect(() => {
        // console.log("⛄❄️ TrieuNinhHan ~ DefaultLayout ~ userInfo:", userInfo);
        if (userInfo?.user_type === "SUPPLIER") {
            setRolerouter(routerSupplier);
        } else {
            setRolerouter([]);
        }
    }, [JSON.stringify(userInfo)]);
    return (
        <>
            <div className="div-default-container flex-column w-100">
                <Suspense fallback={<LoadingComponent />}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/cua-hang/:idUser" component={ShopDetail} />
                        <Route exact path="/tim-kiem-cua-hang" component={Shop} />
                        <Route exact path="/danh-muc" component={Categories} />
                        <Route exact path="/san-pham" component={Products} />
                        <Route exact path="/san-pham/:slugProduct" component={ProductDetail} />
                        <Route exact path="/combo/:slugProduct" component={ComboDetail} />
                        <Route exact path="/danh-muc/:slugCate" component={ProductsCategory} />
                        <Route exact path="/danh-muc/:slugCate/:slugSubCate" component={ProductsCategory} />
                        <Route exact path="/loc-san-pham" component={FilterProductsMobile} />
                        <Route exact path="/loc-danh-muc/:slugCate" component={FilterCategoriesMobile} />
                        <Route exact path="/loc-danh-muc/:slugCate/:slugSubCate" component={FilterCategoriesMobile} />
                        <Route exact path="/share/:code" component={CollaboratorsRegister} />
                        <PrivateRoute exact path="/news" component={News} />
                        <Route exact path="/news/:slugPost" component={NewsDetail} />
                        <Route exact path="/policy" component={Policy} />
                        <Route exact path="/rules" component={Rules} />{" "}
                        <Route exact path="/policy-commission" component={PolicyCommision} />
                        <Route exact path="/support" component={Support} />
                        <Route exact path="/combo" component={ComboList} />
                        {/* PrivateRoute */}
                        <PrivateRoute exact path="/cart" component={Cart} />
                        <PrivateRoute exact path="/order" component={Order} />
                        <PrivateRoute exact path="/address" component={Address} />
                        <PrivateRoute exact path="/payment" component={Payment} />
                        <PrivateRoute exact path="/orderInfo" component={OrderInfo} />
                        <CollabRoute exact path="/collaborators" component={Collaborators} />
                        <PrivateRoute exact path="/referralCode" component={ReferralCode} />
                        <PrivateRoute exact path="/teamList" component={TeamList} />
                        <PrivateRoute exact path="/teamList/:idMember" component={TeamList} />
                        <PrivateRoute exact path="/teamListB/:idMember" component={TeamListB} />
                        {/* <PrivateRoute exact path="/salesRegister" component={SalesRegister} /> */}
                        {/* <PrivateRoute exact path="/sales-register" component={SalesRegister} /> */}
                        <PrivateRoute exact path="/user/main" component={MainInfo} />
                        <PrivateRoute exact path="/collaboratorsRegister" component={CollaboratorsRegister} />
                        <PrivateRoute exact path="/suppliersRegister" component={SuppliersRegister} />
                        <PrivateRoute exact path="/ordersSold" component={OrdersSold} />
                        <PrivateRoute exact path="/withdrawMoney" component={WithdrawMoney} />
                        <PrivateRoute exact path="/transMoney" component={TransMoney} />
                        <PrivateRoute exact path="/userstatistics" component={InfoUser} />
                        <PrivateRoute exact path="/withdrawMoneyHistory" component={WithdrawMoneyHistory} />
                        <PrivateRoute exact path="/user" component={User} />
                        {/* <PrivateRoute exact path="/thong-ke-hoa-hong" component={BonusStatistical} /> */}
                        {/* <PrivateRoute exact path="/thong-ke-thu-nhap" component={IncomeStatistics} /> */}
                        <PrivateRoute exact path="/likeProducts" component={LikeProducts} />
                        <PrivateRoute exact path="/setting" component={Setting} />
                        <PrivateRoute exact path="/orderHistory" component={OrderHistory} />
                        <PrivateRoute exact path="/orderHistory/:id" component={OrderHistoryDetail} />
                        <PrivateRoute exact path="/orderProduct" component={OrderProduct} />
                        <PrivateRoute exact path="/policy/supportpolicy" component={SupportPolicy} />
                        <PrivateRoute exact path="/userInfo" component={UserInfo} />
                        <PrivateRoute exact path="/editUserInfo" component={EditUserInfo} />
                        {roleRouter.map((item) => (
                            <PrivateRoute exact path={item?.path} component={item?.component} key={item?.id} />
                        ))}
                        {/* <PrivateRoute exact path="/myProducts" component={MyProducts} />
                        <PrivateRoute exact path="/addProduct" component={AddProduct} />
                        <PrivateRoute exact path="/addProduct/:id" component={AddProduct} /> */}
                        {/* <PrivateRoute exact path="/addVoucher" component={AddVoucher} />
						<PrivateRoute exact path="/addVoucher/:id" component={AddVoucher} /> */}
                        <PrivateRoute exact path="/order/:id" component={OrderDetail} />
                        <PrivateRoute exact path="/orderProductStatus/:id" component={OrderProductDetailStatus} />
                    </Switch>
                </Suspense>
            </div>

            {window.innerWidth >= 768 && <FooterHomeWebsite />}
        </>
    );
};

export default DefaultLayout;
