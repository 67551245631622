import axiosClient from "../axiosClient";

const userAPI = {
    async loginWithGoogle(data) {
        const url = `auth/login_with_google`;
        const resp = await axiosClient.post(url, data);

        return resp;
    },

    async loginWithFacebook(data) {
        const url = `auth/login_with_facebook`;
        const resp = await axiosClient.post(url, data);

        return resp;
    },

    async loginWithPhone(data) {
        const url = `auth/login_with_phone`;
        const resp = await axiosClient.post(url, data);

        return resp;
    },

    async getDataHomePage(params) {
        const url = `/user/home_page`;
        const resp = await axiosClient.get(url, params);

        return resp;
    },

    async getMembers(userId, params) {
        const url = `/user/get_member/${userId}`;
        const resp = await axiosClient.get(url, { params });

        return resp;
    },

    async requestContributorTransaction(data) {
        const url = `user/withdraw/v2`;
        const resp = await axiosClient.post(url, data);

        return resp;
    },
    async phakeMoney(body) {
        const url = `/user/fake_wallet`;
        const resp = await axiosClient.post(url, body);

        return resp;
    },
    async findOne(userId, params) {
        const url = `/user/${userId}`;
        const resp = await axiosClient.get(url, { params });
        return resp;
    },

    async getShopList(params) {
        const url = `/user/search_shop`;
        const resp = await axiosClient.get(url, { params });
        return resp;
    },

    async getUserInfo(params) {
        const url = `user/profile`;
        const resp = await axiosClient.get(url, { params });
        return resp;
    },
    async transferMoney(data) {
        const url = `user/transfer_money`;
        const resp = await axiosClient.post(url, data);

        return resp;
    },
};

export default userAPI;
