import { ArrowLeftOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Dropdown, Input, Menu } from "antd";
import classNames from "classnames";
import queryString from "query-string";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { cartItemsCountSelector } from "src/containers/Cart/selector";
import { setSearchInputStore } from "src/redux/actions/ProductActions";
import logo from "../../../assets/images/logo_ahappi.png";

const HeaderMobile = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const cartItemsCount = useSelector(cartItemsCountSelector);
    const productStore = useSelector((state) => state.product);
    const { search } = productStore.filters;

    const queryParams = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    const [searchValue, setSearchValue] = useState("product");

    const handleChange = (e) => {
        dispatch(setSearchInputStore(e.target.value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const searchName = e.target.search.value.trim();
        if (!searchName) return;

        let filters = {};

        if (searchValue === "product") {
            filters.search = searchName;
            history.push({
                pathname: "/san-pham",
                search: queryString.stringify(filters),
            });
        } else {
            filters.name = searchName;
            history.push({
                pathname: "/tim-kiem-cua-hang",
                search: queryString.stringify(filters),
            });
        }

        // console.log("filters: ", filters);
    };

    const menu = (
        <Menu className="menu-search-mobile-container">
            <Menu.Item
                onClick={() => setSearchValue("product")}
                key="1"
                className={classNames({ "active-search": searchValue === "product" })}
            >
                Tìm sản phẩm
            </Menu.Item>
            <Divider className="my-0" />
            <Menu.Item
                onClick={() => setSearchValue("shop")}
                key="2"
                className={classNames({ "active-search": searchValue === "shop" })}
            >
                Tìm cửa hàng
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        dispatch(setSearchInputStore(queryParams.search || queryParams.name || ""));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.search]);

    return (
        <div>
            {props.scroll ? (
                <div className="w-100 d-flex justify-content-between pt-2">
                    {props.haveFilterInput ? (
                        <form onSubmit={handleSubmit} style={{ width: "70%" }}>
                            <Input
                                placeholder="Bạn tìm gì hôm nay?"
                                prefix={<SearchOutlined />}
                                autoComplete="off"
                                size="large"
                                value={search}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                name="search"
                            />
                        </form>
                    ) : (
                        <>
                            <ArrowLeftOutlined onClick={() => history.push(props.linkGoback)} />
                            <strong>{props.title}</strong>
                        </>
                    )}

                    <div
                        className={`d-flex ${props.haveNotiIcon ? `justify-content-between` : `justify-content-end`} ${
                            props.haveFilterInput ? `div-icon-default-layout` : `me-3`
                        }`}
                    >
                        {props.haveNotiIcon && (
                            <Badge
                                count={cartItemsCount}
                                overflowCount={99}
                                style={{ backgroundColor: "#EB4335", color: "#333" }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-bell"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                </svg>
                            </Badge>
                        )}
                        <Badge
                            count={cartItemsCount || 0}
                            overflowCount={99}
                            onClick={() => history.push("/cart")}
                            style={{ backgroundColor: "#FBBC12", color: "#333" }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-cart"
                                viewBox="0 0 16 16"
                            >
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </Badge>
                    </div>
                </div>
            ) : (
                <div className="text-center">
                    <Link to="/">
                        {" "}
                        <img src={logo} width={110} height={46} alt="" />
                    </Link>
                    <div
                        className={`d-flex ${
                            props.haveNotiIcon ? `justify-content-between` : `justify-content-end`
                        } div-icon-default-layout`}
                    >
                        {props.haveNotiIcon && (
                            <Badge
                                count={cartItemsCount || 0}
                                overflowCount={99}
                                style={{ backgroundColor: "#EB4335", color: "#333" }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-bell-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                </svg>
                            </Badge>
                        )}
                        <Badge
                            count={cartItemsCount || 0}
                            overflowCount={99}
                            onClick={() => history.push("/cart")}
                            style={{ backgroundColor: "#FBBC12", color: "#333" }}
                            className="badge-container"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-cart-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </Badge>
                    </div>
                    <form onSubmit={handleSubmit} className="search-form-mobile">
                        <Input
                            placeholder="Bạn tìm gì hôm nay?"
                            prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                            size="large"
                            autoComplete="off"
                            style={{ marginTop: "8px" }}
                            value={search}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            name="search"
                        />

                        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                            <Button style={{ display: "flex", alignItems: "center" }}>
                                Tìm theo... <DownOutlined />
                            </Button>
                        </Dropdown>
                    </form>
                    {props.title && (
                        <div className="pt-2">
                            <ArrowLeftOutlined className="icon-back-header" onClick={() => history.goBack()} />
                            <strong>{props.title}</strong>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default memo(HeaderMobile);
