import { REMOVE_PAYMENT_TYPE, SET_PAYMENT_TYPE } from "src/redux/actions/OrderActions/type";

import { payments } from "src/utils/constants.js/";

const initState = {
	paymentType: JSON.parse(sessionStorage.getItem("paymentType")) || payments[0]
};

const orderReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_PAYMENT_TYPE: {
			const newPaymentType = action.payload;

			sessionStorage.setItem("paymentType", JSON.stringify(newPaymentType));
			return {
				...state,
				paymentType: newPaymentType,
			};
		}

		case REMOVE_PAYMENT_TYPE: {
			const newPaymentType = {};

			sessionStorage.removeItem("paymentType");
			return {
				...state,
				paymentType: newPaymentType,
			};
		}

		default:
			return state;
	}
};

export default orderReducer;
