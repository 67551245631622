import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "bootstrap/dist/css/bootstrap.css";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { Login } from "./containers/Login";
import DefaultLayout from "./layouts/DefaultLayout";
import ScrollToTop from "./utils/ScrollToTop";
import userAPI from "./api/userAPI";
import { useDispatch } from "react-redux";
import { UPDATE_PROFILE } from "./redux/actions/UserActions/type";
import cartAPI from "./api/cart";
import { renewCart } from "./redux/actions/CartActions";

function App() {
    const dispatch = useDispatch();
    const handleUpdateProfile = async () => {
        try {
            const res = await cartAPI.getAll({});
            const res1 = await userAPI.getUserInfo();
            console.log("⛄❄️ TrieuNinhHan ~ handleOk ~ res:", res);
            const ids = res?.results?.objects?.rows?.map((item) => item?.product?.id);
            if (ids.length > 0) {
                const res2 = await cartAPI.checkDeleteProducts({
                    arr_product_id: ids,
                });
            }
            if (res?.code === 200) {
                dispatch(
                    renewCart(
                        res?.results?.objects?.rows?.map((item) => ({
                            ...item,
                        })),
                    ),
                );
            }

            if (res1?.code === 200) {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: res1?.results?.object,
                });
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:25 ~ handleUpdateProfile ~ error:", error);
        }
    };
    useEffect(() => {
        handleUpdateProfile();
    }, []);
    return (
        <div className="App">
            <Suspense
                fallback={
                    <div className="loading-component">
                        <p>Đang tải...</p>
                    </div>
                }
            >
                <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route path="/" component={DefaultLayout} />
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
