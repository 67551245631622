import {
  GET_CATEGORY_LIST, GET_HISTORY_FORM
} from "src/redux/actions/CategoryActions/types";

const initState = {
  categories: [],
  productInfo: []
};

const productsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case GET_HISTORY_FORM: {
      return {
        ...state,
        productInfo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default productsReducer;
