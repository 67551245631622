import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Header from "src/layouts/header/Header";
export const handleClickToSendEmail = () => {
    const emailAddress = "info@ahappi.com";
    const emailTitle = "Hỗ trợ";
    const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(
        emailTitle,
    )}`;

    // Mở tab mới với đường link Gmail compose
    window.open(gmailComposeLink, "_blank");
};
const EmailButton = () => {
    const history = useHistory();

    useEffect(() => {
        handleClickToSendEmail();
        history.push("/");
    }, []);
    return (
        <div>
            <Header haveFilterInput={true} />
        </div>
    );
};

export default EmailButton;
