import axiosClient from '../axiosClient';

const searchHistoryAPI = {
	async getSearchKeyword(params) {
		const url = `/search_history/get_viral_search_keyword`;
		const resp = await axiosClient.get(url, { params });

		return resp;
	},
};

export default searchHistoryAPI;
