import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAcMSOAVZpP2mRCGZ-CxikA-qMGnF9_WkU",
	authDomain: "ahappi.com",
	projectId: "ahappi",
	storageBucket: "ahappi.appspot.com",
	messagingSenderId: "16272440979",
	appId: "1:16272440979:web:2adc36fc79ea1538f05766",
	measurementId: "G-S6ZSVLKJEV",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const ggprovider = new firebase.auth.GoogleAuthProvider();
ggprovider.setCustomParameters({ prompt: "select_account" });

const fbProvider = new firebase.auth.FacebookAuthProvider();

export const signInWithGoogle = () => auth.signInWithPopup(ggprovider);
export const signInWithFacebook = () => auth.signInWithPopup(fbProvider);

export default firebase;
