import "animate.css";
import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { ModalProvider } from "./contexts/ModalContext";
import { UserProvider } from "./contexts/UserContext";
import "./index.css";
import store from "./redux/stores";
import reportWebVitals from "./reportWebVitals";
import { IconContext } from "react-icons";
import { LoadingProvider } from "./contexts/LoadingContext";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<IconContext.Provider value={{ color: "blue", className: "global-class-name" }}>
				<LoadingProvider>
					<UserProvider>
						<ModalProvider>
							<App />
						</ModalProvider>
					</UserProvider>
				</LoadingProvider>
			</IconContext.Provider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
