import axios from "axios";
import queryString from "query-string";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "Content-Type": "application/json",
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

axios.defaults.baseURL = process.env.REACT_APP_API;

axiosClient.interceptors.request.use(async (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (!!userInfo) {
        config.headers.Authorization = `Bearer ${userInfo.token}`;
    }

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        // console.log("⛄❄️ TrieuNinhHan ~ file: axiosClient.js:26 ~ response:", response);
        if (response && response.data) {
            return response.data;
        }

        return response;
    },
    (error) => {
        console.log("⛄❄️ TrieuNinhHan ~ file: axiosClient.js:33 ~ error:", error?.response?.data?.code);
        if (error?.response?.data?.code === 560) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
        }
        // Handle errors
        // console.log(error.response.data);
        throw error.response.data;
    },
);

export default axiosClient;
