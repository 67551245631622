import { MessageFilled, PhoneFilled } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import zaloLogo from 'src/assets/images/zalo.png';
import { ModalContext } from 'src/contexts/ModalContext';
import '../styles.scss';
import logoAhappi from 'src/assets/images/logo_ahappi2.png';
import * as ProductAPI from 'src/api/productAPI';
import { Link } from 'react-router-dom';

function ShopItem({ shop }) {
    const modalContext = useContext(ModalContext);

    const [userTotalProduct, setUserTotalProduct] = useState(0);

    const getUserProducts = async () => {
        try {
            const userProductRes = await ProductAPI.getSubList({
                fields: ['$all'],
                filter: { user_id: shop.id },
            });
            const newUserProductObj = userProductRes?.results?.objects;

            setUserTotalProduct(newUserProductObj.count);
        } catch (error) {}
    };

    const handleSendSmsZalo = () => {
        if (shop?.phone) {
            window.open(`https://zalo.me/${shop?.phone}`);
        } else {
            modalContext.showConfirm('Thông báo', 'Người dùng này không có số điện thoại!');
        }
    };

    const handleSendSms = () => {
        if (shop?.phone) {
            window.open(`sms:${shop?.phone}`);
        } else {
            modalContext.showConfirm('Thông báo', 'Người dùng này không có số điện thoại!');
        }
    };

    const handleCall = () => {
        if (shop?.phone) {
            window.open(`tel:${shop?.phone}`);
        } else {
            modalContext.showConfirm('Thông báo', 'Người dùng này không có số điện thoại!');
        }
    };

    useEffect(() => {
        getUserProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="seller-container">
            <Link to={`/cua-hang/${shop?.id}`} className="seller-left-box">
                <img
                    src={shop?.avatar || logoAhappi}
                    alt=""
                    className={classNames('avatar-seller', {
                        'avatar-desktop': window.innerWidth >= 768,
                    })}
                />
                <div className="seller-info">
                    <p className="seller-name">{shop?.fullname}</p>
                    <p className="title">Tổng sản phẩm: {userTotalProduct || 0}</p>
                    {/* {shop?.phone && <p className="title">SĐT: {shop?.phone}</p>} */}
                </div>
            </Link>
            <div className="seller-right-box">
                {window.innerWidth >= 768 ? (
                    <>
                        <button onClick={handleSendSms} className="button-send-message-desktop">
                            <MessageFilled className="icon-button-desktop" />
                            <span className="title">Nhắn tin</span>
                        </button>
                        <button onClick={handleSendSmsZalo} className="button-zalo-desktop">
                            <img src={zaloLogo} alt="zaloLogo" />
                            <span className="title">Nhắn tin</span>
                        </button>
                    </>
                ) : (
                    <>
                        <button onClick={handleCall} className="icon-button">
                            <PhoneFilled />
                        </button>
                        <button onClick={handleSendSms} className="icon-button">
                            <MessageFilled />
                        </button>
                        <button
                            onClick={handleSendSmsZalo}
                            href={`https://zalo.me/${shop?.phone}`}
                            className="icon-button-zalo"
                        >
                            <img src={zaloLogo} alt="zaloLogo" />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default ShopItem;
