import React, { createContext } from "react";
import { useSelector } from "react-redux";
import axiosClient from "src/api/axiosClient";

const UserContext = createContext();

function UserProvider({ children }) {
	const userInfo = useSelector((state) => state.user.userInfo);
	const isLoggedIn = !!userInfo?.token;

	const handleRedirect = (path) => {
		window.location.replace(path);
	};

	const value = {
		handleRedirect,
		isLoggedIn,
	};

	const getUser = async () => {
		try {
			const res = await axiosClient.get(`/user/${userInfo?.object?.id}?fields=["$all"]`);
			const userProfile = {
				object: res?.results?.object,
				token: userInfo.token,
			};
			localStorage.setItem("userInfo", JSON.stringify(userProfile));
			// console.log("😭PCP ~ file: UserContext.js ~ line 12 ~ getUser ~ res", res.results.object)
		} catch (error) {}
	};

	React.useEffect(() => {
		if (userInfo.object) {
			getUser();
		}
	}, [userInfo]);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };
