import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const CollabRoute = ({ children, ...rest }) => {
    const location = useLocation();

    const userStore = useSelector((state) => state.user);
    const user_type = userStore?.userInfo?.object?.user_type;

    return (
        // token
        user_type !== "USER" ? (
            <PrivateRoute {...rest} />
        ) : (
            <Redirect
                to={{
                    pathname: "/collaboratorsRegister",
                    state: { from: location },
                }}
            />
        )
    );
};

export default CollabRoute;
