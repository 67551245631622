import { Skeleton } from "@material-ui/lab";
import { List } from "antd";
import React from "react";
import "./styles.scss";

function ProductSkeletonList({ length = 12, xs = 2, sm = 2, md = 3, lg = 4, xl = 5, xxl = 5 }) {
	const data = Array.from(Array(length).keys());

	const renderProductItem = (item) => {
		return (
			<List.Item
				key={item}
				className="div-item-product flex-column product-item-skeleton-container"
			>
				<div className="product-item-skeleton">
					<Skeleton animation="wave" variant="rect" width="100%" height="170px" />
					<Skeleton animation="wave" width="50%" height="26px" style={{ marginTop: "12px" }} />
					<Skeleton animation="wave" />
					<Skeleton animation="wave" width="50%" />
				</div>
			</List.Item>
		);
	};

	return (
		<List
			grid={{
				gutter: 10,
				xs: 2,
				sm: 2,
				md: md || 3,
				lg: lg || 4,
				xl: xl || 5,
				xxl: xxl || 5,
			}}
			dataSource={data}
			renderItem={(item) => renderProductItem(item)}
		></List>
	);
}

export default ProductSkeletonList;
