import { DollarCircleOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNumberWithCommas } from "src/utils/numberUtils";
import "../styles.scss";
import { UPDATE_PROFILE } from "src/redux/actions/UserActions/type";
import userAPI from "src/api/userAPI";

const CollabMain = (props) => {
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const userInfo = userStore?.userInfo?.object;
    const handleUpdateProfile = async () => {
        try {
            const res1 = await userAPI.getUserInfo();
            // console.log("//⛄❄️ TrieuNinhHan ~ handleOk ~ res:", res1);
            if (res1?.code === 200) {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: res1?.results?.object,
                });
            }
        } catch (error) {
            console.log("⛄❄️ TrieuNinhHan ~ file: TransMoney.js:25 ~ handleUpdateProfile ~ error:", error);
        }
    };
    useEffect(() => {
        handleUpdateProfile();
    }, []);
    return (
        <div className="div-default-layout" style={{ padding: 0 }}>
            <div className="div-bg-pd collab-main-container">
                <h6 className="font-weight-bold my-2">Thống kê người dùng</h6>
                <Divider className="mt-3 mb-0" />
                <div className="div-bg-pd w-100">
                    <Row gutter={16} className="pb-2 statistics-uer-card-box">
                        <Col span={8}>
                            <Link to={`/ordersSold`} className="user-desktop-link">
                                <Card size="small" title="Hoa hồng tuyến dưới" className="total-product-card w-100">
                                    <div className="body-box">
                                        <span className="progress_number">
                                            {getNumberWithCommas(userInfo?.total_bonus) || 0}đ
                                        </span>
                                        <DollarCircleOutlined className="icon" />
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Link to={`/ordersSold`} className="user-desktop-link">
                                <Card
                                    size="small"
                                    title="Tài sản"
                                    className="total-product-card total-product-card-v2 w-100"
                                >
                                    <div className="body-box">
                                        <span className="progress_number">
                                            {getNumberWithCommas(userInfo?.total_bonus_by_supplier) || 0}đ
                                        </span>
                                        <DollarCircleOutlined className="icon" />
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                        {userInfo?.user_type === "BUSINESS_AGENT" && (
                            <Col span={8}>
                                <Link to={`/teamList/${userInfo?.id}`} className="user-desktop-link">
                                    <Card size="small" title="Tổng thành viên" className="total-income-card w-100">
                                        <div className="body-box">
                                            <span className="progress_number">{userInfo?.total_member || 0}</span>
                                            <UsergroupAddOutlined className="icon" />
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={16} className="pb-2 statistics-uer-card-box"></Row>
                </div>
            </div>
        </div>
    );
};

export default CollabMain;
