export declare type ThemeStyle = "auto" | "light" | "dark";
export enum PaymentType {
    COD = "COD",
    ONLINE = "ONLINE",
}
export enum PostType {
    SALE = "SALE", // Khuyến mãi
    NEWS = "NEWS", // Tin tức mới
    ORDER = "ORDER", // các điều khoản
    TERMS = "TERMS",
    RULES = "RULES",
}
export enum DiscountType {
    CONTRIBUTOR = "CONTRIBUTOR",
    SHARED = "SHARED",
    STANDARD = "STANDARD",
}

export enum ActivityOrder {
    PROCESSING = "PROCESSING",
    CONFIRMED = "CONFIRMED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELED",
}
export enum PaymentStatus {
    NOT_PAID = "NOT_PAID",
    PAID = "PAID",
    PENDING = "PENDING",
}
export interface PostProps {
    id: string | number;
    type: null | string;
    image: string;
    title: string;
    date: string;
    view: string;
    description: string;
}
export interface AgentProps {
    open_time: string;
    close_time: string;
    arr_image: string[];
    description: null;
    status: true;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    id: number;
    region: null | string;
    name: string;
    longitude: number;
    latitude: number;
    address: string;
    phone: null | string;
    district: null | string;
    distance: null | number | string;
    image: null | string;
    thumbnail: null | string;
    website: null | string;
    geometry: Geometry;
    createdAt: Date | string;
    updatedAt: Date | string;
    disabledAt: null;
}
export interface ProductProps {
    id: string;
    category_id: string;
    title: string;
    other: string;
    title_en?: string;
    price: number;
    discount_price?: number;
    description: string;
    expire_date: string;
    thumbnail: string;
    image: string[];
    inventory_amount: number;
    status_enum: string;
    contributor_bonus_rate: number;
    contributor_rate: number;
    introduction_rate: number;
    bonus_point: number;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date | string;
    updated_at: Date | string;
    deleted_at: null;
    sub_category_id: string;

    user_id: string;
    color_list: any[];
    star: number;
    weight: number;
    size: string[];
    brand: string;
    fake_price: number;
    collab_price: number;
    quantity_sold: number;
    distributor: any;
    country_of_origin: any;
    best_sale: boolean;
    amount_search: number;
}
export interface CartProps extends ProductProps {
    quantity: number;
}
export interface Geometry {
    type: null | string;
    coordinates: number[];
}

// {"avatar": "https://lh3.googleusercontent.com/a-/AOh14GjlRRtOsH_bUOkZIPOuWApvD9XNV1yAuMPKD_ei7w=s96-c", "birthday": null, "bonus_point": null, "created_at": "2021-08-09T19:33:21.969Z", "created_at_unix_timestamp": "1628512401963", "deleted_at": null, "email": "vu111293@gmail.com", "firstLogin": false, "fullname": "Vũ Lê Quốc", "gender": null, "id": "fbd12190-f90d-11eb-ae56-bbef45f6ec93", "is_collab": false, "latitude": 0, "location": null, "login_type": "GOOGLE", "longitude": 0, "member_type": "FREE", "phone": "", "role": "USER", "status": true, "updated_at": "2021-08-09T19:33:21.969Z", "username": "vu111293@gmail.com", "wallet": null}
export interface HistoryWithDrawProps {
    id: string;
    user_id: string;
    order_related: string;
    change_amount: number;
    bank_number: string;
    bank_type: string;
    note: string;
    status_enum: string;
    type: string;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: string;
    updated_at: string;
    deleted_at: null;
}
export interface UserProps {
    id: string;
    username: string;
    phone: string;
    level: number;
    avatar: string;
    birthday: string;
    email: string;
    fullname: string;
    gender: string;
    login_type: string;
    member_type: string;
    location: string;
    longitude: number;
    latitude: number;
    bonus_point: number;
    wallet: number;
    is_collab: boolean;
    member: number;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: string;
    updated_at: string;
    deleted_at: null;
    defaultAddress?: AddressProps | undefined;
}
export interface CategoryProps {
    id?: string;
    title?: string;
    image?: string;
    order?: number;
    hot?: boolean;
    status?: boolean;
    created_at_unix_timestamp?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: null;
    sub_categories?: SubCategoryProps[];
}
export interface SubCategoryProps {
    id?: string;
    category_id?: string;
    title?: string;
    image?: string;
    status?: boolean;
    created_at_unix_timestamp?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: null;
}
export interface AddressProps {
    id: string;
    user_id: string;
    address: string | undefined | null;
    phone: string | undefined | null;
    fullname: string;
    ward: string | null;
    district: string | null;
    city: string | null;
    is_default: boolean;
    longitude: number;
    latitude: number;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
}

export interface OrderCreateProps {
    shop_id: string;
    voucher_id: string | null;
    payment_type: PaymentType;
    address_id: string;
    items: OrderCart[];
}
export interface OrderProps {
    id: string;
    user_id: string;
    address_id: null | string;
    shop_id: string;
    voucher_id: null | string;
    fee_total: number;
    fee_ship: number;
    discount: number;
    total_cost: number;
    payment_type: PaymentType;
    payment_status: PaymentStatus | null;
    total_contributor_bonus: number;
    bonus_point: number;
    discount_type: DiscountType;
    discount_by_product: number;
    discount_by_level: number;
    price_temp: number;
    longitude: null;
    latitude: null;
    fullname: string;
    phone: string;
    address: string;
    ward: null | string;
    district: null | string;
    city: null | string;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    order_items: OrderItemProps[];
    order_activitys: OrderActivitys[];
}
export interface OrderActivitys {
    activity: ActivityOrder;
    created_at_unix_timestamp: string;
}

export interface OrderItemProps {
    id: string;
    order_id: string;
    product_id: string;
    price: number;
    quantity: number;
    contributor_bonus_rate: number;
    discount_price: number;
    bonus_point: number;
    thumbnail: string;
    description: null;
    image: any[];
    title: string;
    introduction_rate: number;
    contributor_rate: number;
    status: boolean;
    created_at_unix_timestamp: null;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
}
export interface OrderCart {
    product_id: string;
    title?: string;
    price?: number;
    description?: string;
    discount_price?: number;
    thumbnail?: string;
    image?: string[];
    contributor_bonus_rate?: number;
    contributor_rate?: number;
    introduction_rate?: number;
    bonus_point?: number;
    quantity: number;
}
export interface AddressOrder {
    fullname: string;
    phone: string;
    address: string;
    ward: string;
    district: string;
    city: string;
}

export interface PreOrderCallbackProps {
    order: OrderCallbackProps;
    order_items: OrderItemCallBackProps[];
}

export interface OrderCallbackProps {
    user_id: string;
    shop_id: string;
    address_id: string;
    voucher_id: null;
    fee_total: number;
    fee_ship: number;
    discount: number;
    discount_type: DiscountType;
    discount_by_product: number;
    discount_by_level: number;
    price_temp: number;
    bonus_point: number;
    total_cost: number;
    total_contributor_bonus: number;
    fullname: string;
    phone: string;
    address: string;
    ward: string;
    district: string;
    city: string;
    latitude: null;
    longitude: null;
    payment_type: string;
    payment_status: string;
    status: boolean;
}

export interface OrderItemCallBackProps {
    product_id: string;
    title: string;
    price: number;
    quantity: number;
    description: null;
    thumbnail: string;
    image: any[];
    bonus_point: number;
    discount_price: number;
    contributor_bonus_rate: number;
    contributor_rate: number;
    introduction_rate: number;
}

export interface CollabCreateProps {
    fullname: string;
    phone: string;
    address: string;
    card_id: string;
    front_identification: string;
    back_identification: string;
    code?: string;
}
export interface FavoriteProps {
    id: string;
    user_id: string;
    product_id: string;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    product: ProductProps;
}
export interface CreateRequestWithdrawMoneyProps {
    change_amount: number;
    note: string;
    status_enum: string;
    type: string;
    bank_number: string;
    bank_type: string;
}
export interface NotificationProps {
    id: string;
    user_id: string;
    post_id: string;
    order_id: string;
    title: string;
    message_title: string;
    message_content: string;
    type: string;
    seen: boolean;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    order: OrderProps;
    post: any;
}
export interface BannerProps {
    created_at: Date;
    created_at_unix_timestamp: string;
    deleted_at: null;
    id: string;
    key: string;
    status: boolean;
    type: string;
    updated_at: Date;
    value: string;
    url: string;
}

export interface HomePageProps {
    header_banner: BannerProps[];
    connect_banner: BannerProps[];
    most_search_product: ProductProps[];
    connect: any[];
    hot_categorys: any[];
    hot_today_banner: BannerProps[];
    new_products: ProductProps[];
    best_sale_products: any[];
    viral_search_product: any[];
    footer_banner: any[];
}
export interface referalCodeProps {
    id: string;
    user_id: string;
    code: string;
    used: boolean;
    status: boolean;
    created_at_unix_timestamp: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
}
export interface memberInGroup {
    created_at: Date;
    created_at_unix_timestamp: string;
    deleted_at: null;
    id: string;
    member_id: string;
    members: UserProps;
    status: true;
    updated_at: Date;
    user: { level: number; username: string };
    user_id: string;
}
