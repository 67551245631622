import { Pagination } from "@material-ui/lab";
import { Divider } from "antd";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import userAPI from "src/api/userAPI";
import ButtonLoadMore from "src/components/Button/ButtonLoadMore";
import NoDataDesktop from "src/components/NoData/NoDataDesktop";
import NoDataMobile from "src/components/NoData/NoDataMobile";
import Header from "../../layouts/header/Header";
import ShopItemMobile from "./components/ShopItemMobile";

const Shop = () => {
	const location = useLocation();
	const history = useHistory();

	const [isLoadingProduct, setIsLoadingProduct] = useState(true);
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [shopList, setShopList] = useState([]);
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: 10,
	});

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
			limit: Number.parseInt(params.limit) || 10,
			// search: params.search || '',
		};
	}, [location.search]);

	const getShopList = async (queryParams) => {
		setIsLoadingProduct(true);
		try {
			const res = await userAPI.getShopList({
				shop: queryParams?.name,
				order: '[["created_at","desc"]]{"members":["$all"]}]',
				limit: queryParams.limit,
				page: queryParams.page,
			});

			const newShopList = res.results.objects.rows;
			const newPagination = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};

			setPagination(newPagination);
			setShopList(newShopList);
			setIsLoadingProduct(false);
		} catch (err) {
			setIsLoadingProduct(false);
		}
	};

	const getShopListLoadMore = async (page) => {
		setIsLoadMore(true);
		try {
			const res = await userAPI.getShopList({
				shop: queryParams?.name,
				order: '[["created_at","desc"]]{"members":["$all"]}]',
				limit: queryParams.limit,
				page: page,
			});

			const newData = shopList.concat(res.results.objects.rows);
			setShopList(newData);
			setIsLoadMore(false);
		} catch (err) {
			setIsLoadMore(false);
		}
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		history.push({
			pathname: history.location.pathname,
			search: queryString.stringify(filters),
		});
	};

	const handleLoadMore = () => {
		setPagination({
			...pagination,
			page: pagination.page + 1,
		});
		getShopListLoadMore(pagination.page + 1);
	};

	useEffect(() => {
		getShopList(queryParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams]);

	return (
		<div className="div-default-layout d-flex flex-column shop-container">
			<Header title="Cửa hàng" linkGoback="/" />

			{window.innerWidth >= 768 ? (
				<div className="container shop-item-desktop">
					{pagination.total ? (
						<>
							{shopList?.map((item) => (
								<div key={item?.id} className="shop-item-mobile-container">
									<ShopItemMobile shop={item} />
									<Divider className="my-2" />
								</div>
							))}
						</>
					) : (
						<NoDataDesktop title="Rất tiếc, không tìm thấy cửa hàng phù hợp với lựa chọn của bạn" />
					)}
				</div>
			) : (
				<>
					{pagination.total ? (
						<div className="shop-item-mobile-container">
							{shopList?.map((item) => (
								<div key={item?.id}>
									<ShopItemMobile shop={item} />
									<hr className="m-0" />
								</div>
							))}
						</div>
					) : (
						<div>
							<NoDataMobile
								heightHeader="147px"
								title="Rất tiếc, không tìm thấy cửa hàng phù hợp với lựa chọn của bạn"
							/>
						</div>
					)}
				</>
			)}

			{pagination.total !== shopList?.length && (
				<div className="pagination-shop">
					{window.innerWidth >= 768 ? (
						<Pagination
							page={pagination.page}
							count={Math.ceil(pagination.total / pagination.limit)}
							onChange={handleChangePage}
							color="primary"
						/>
					) : (
						<ButtonLoadMore onClick={handleLoadMore} />
					)}
				</div>
			)}
		</div>
	);
};

export default Shop;
