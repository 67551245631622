import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import logoAhappi from 'src/assets/images/logo_ahappi2.png';
import './styles.scss';

function NoDataMobile({ heightHeader, title = 'Rất tiếc, không tìm thấy sản phẩm phù hợp với lựa chọn của bạn' }) {
    const history = useHistory();

    return (
        <div
            className="no-data-mobile-container"
            style={heightHeader ? { height: `calc(100vh - ${heightHeader})` } : {}}
        >
            <img src={logoAhappi} alt="" className="img-logo" />
            <span className="message">{title}</span>
            <Button onClick={() => history.push('/')} className="go-home">
                Tiếp tục mua sắm
            </Button>
        </div>
    );
}

export default NoDataMobile;
