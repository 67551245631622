import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import postAPI from "src/api/postAPI";
import defaultImg from "src/assets/images/default-img.png";
import { PostType } from "src/constants/types";
import Header from "../../../layouts/header/Header";
import "./styles.scss";

const SupportPolicy = (props) => {
    const history = useHistory();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderChildren = () => {
        return (
            <div className="w-100 flex-column" style={{ position: "relative" }}>
                {/* <img alt="" width={"100%"} height={207} src={defaultImg} style={{ objectFit: "cover" }} /> */}
                <Button
                    className="policy-button-back"
                    style={{
                        backgroundColor: "#808080",
                        color: "#fff",
                        opacity: 0.45,
                        position: "absolute",
                        top: 10,
                        left: 12,
                        textAlign: "center",
                    }}
                    shape="circle"
                    icon={
                        <div>
                            <ArrowLeftOutlined />
                        </div>
                    }
                    onClick={() => history.push("/news")}
                ></Button>
                <div style={{ padding: "12px" }}>
                    <div className="d-flex justify-content-between text-grey-login mt-5 mb-4">
                        <p className="mb-0" style={{ color: "#EB4335" }}>
                            Chính sách hỗ trợ
                        </p>
                        <p className="mb-0">{moment().format("HH:mm DD/MM/YYYY")}</p>
                    </div>
                    <>
                        <strong>Online Return & Exchange Policy</strong>
                        <p className="mt-2">
                            If you are not satisfied with your purchase, return it to us for an exchange or refund,
                            subject to the following terms.
                        </p>
                        <Divider className="my-2" />
                        <strong>Return or Exchange of Merchandise </strong>
                        <p className="mt-2">
                            Purchased Online Returning online is easy! Click here to start your return now! To receive
                            your refund to your original payment method, your online return must be initiated within 60
                            days of the order shipment date. After 60 days of your order shipment date, the only option
                            for refund will be offered in the form of an e-gift card, sent to the email address used on
                            the order. You are responsible for return shipping costs.
                        </p>
                        <Divider className="my-2" />
                        <strong>Exchange of Merchandise Purchased </strong>
                        <p className="mt-2">
                            Online To return items for an exchange, include your order invoice along with your reason
                            for returning or exchanging to the below address. Indicate the full Item Name, Item Number,
                            Size, and Color of the item you would like to exchange it for. Puffinca 123 Street name Way
                            New Albany, OH 43054 US
                        </p>
                        <Divider className="my-2" />
                        <strong>Important Return Information</strong>
                        <p className="mt-2">
                            We refund any merchandise in resalable condition with a copy of your original invoice to the
                            original payment method if mailed to the address listed above within 60 days of the shipping
                            date. If you are making a return after 60 days, your refund will be processed as merchandise
                            credit. You are responsible for return shipping costs. If your order was placed in USD, you
                            may return or exchange in a US Puffinca or PUF store.
                        </p>
                        <div className="d-flex">
                            {/* <img
								src={mailImg}
								width={20}
								style={{ objectFit: "contain", marginRight: "10px" }}
								alt="mailImg"
							/> */}
                            <strong>Email us</strong>
                        </div>
                        <p style={{ color: "#808080", marginLeft: "30px" }}>contact@ahappi.com</p>
                        <Divider className="my-2" />
                        <div className="d-flex">
                            {/* <img
								src={phoneImg}
								width={20}
								style={{ objectFit: "contain", marginRight: "10px" }}
								alt="phoneImg"
							/> */}
                            <strong>Call us</strong>
                        </div>
                        <p style={{ color: "#808080", marginLeft: "30px" }}>+84 766 989 422</p>
                    </>
                </div>
            </div>
        );
    };
    // if (isLoading) {
    //     return (
    //         <div className="demo-loading-container">
    //             <Spin />
    //         </div>
    //     );
    // } else {
    return (
        <>
            {window.innerWidth >= 768 && <Header haveFilterInput={true} title="Tin tức" notBackIcon={true} />}
            <div className="py-2 container">{renderChildren()}</div>
        </>
    );
};
// };

export default SupportPolicy;
