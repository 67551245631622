import {
    ADD_PRODUCT_TO_CART,
    REMOVE_CART,
    REMOVE_CART_ITEM,
    REMOVE_MULTI_CART_ITEM,
    UPDATE_CART,
    RENEW_CART,
} from "./types";

// CART
export const addProductToCartStore = (payload) => ({
    type: ADD_PRODUCT_TO_CART,
    payload,
});

export const updateCartStore = (payload) => ({
    type: UPDATE_CART,
    payload,
});

export const removeCartItemStore = (payload) => ({
    type: REMOVE_CART_ITEM,
    payload,
});

export const removeMultiCartItemStore = (payload) => ({
    type: REMOVE_MULTI_CART_ITEM,
    payload,
});

export const removeCartStore = () => ({
    type: REMOVE_CART,
});

export const renewCart = (payload) => ({
    type: RENEW_CART,
    payload,
});
