export const payments = [
    {
        title: "Thanh toán qua Ví tiêu dùng",
        type: "COD",
    },
    {
        title: "Thanh toán qua Ví tiêu dùng",
        type: "CODddd",
    },
    // {
    //     title: "Thanh toán online",
    //     type: "ONLINE",
    // },
];
