import {
	REMOVE_ADDRESS_CHECKED,
	SET_ADDRESS_CHECKED,
	GET_DEFAULT_ADDRESS,
} from "src/redux/actions/AddressActions/type";

const initState = {
	addressChecked: JSON.parse(sessionStorage.getItem("addressChecked")) || {},
};

const addressReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_ADDRESS_CHECKED: {
			const newAddress = action.payload;

			sessionStorage.setItem("addressChecked", JSON.stringify(newAddress));
			return {
				...state,
				addressChecked: newAddress,
			};
		}

		case GET_DEFAULT_ADDRESS: {
			return {
				...state,
				addressDefault: action.payload,
			};
		}

		case REMOVE_ADDRESS_CHECKED: {
			const newAddress = {};

			sessionStorage.removeItem("addressChecked");
			return {
				...state,
				addressChecked: newAddress,
			};
		}

		default:
			return state;
	}
};

export default addressReducer;
