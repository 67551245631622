import { Button, Form, Input } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import authAPI from "src/api/authAPI";
import { LoadingContext } from "src/contexts/LoadingContext";
import { ModalContext } from "src/contexts/ModalContext";
import { setUserInfoStore } from "src/redux/actions/UserActions";
import firebase, { auth, signInWithGoogle } from "src/services/firebase";
import icon_facebook from "../../../assets/images/facebook.png";
import icon_google from "../../../assets/images/google-plus.png";
import logo from "../../../assets/images/logo_ahappi.png";
import "../styles.scss";

const Login = () => {
    const [form] = Form.useForm();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const { error } = useContext(ModalContext);
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState("");

    const userInfo = useSelector((state) => state.user.userInfo);
    const isLoggedIn = !!userInfo?.token;

    const [phoneNumber, setPhoneNumber] = useState("");
    const [errors, setErrors] = useState("");
    const rx_live = /^\d*$/;

    const shareCode = history.location.state?.shareCode;
    const params = history.location.state?.params;

    useEffect(() => {
        isLoggedIn && history.push("/");
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
            size: "invisible",
            callback: function (response) {
                // console.log("Captcha Resolved");
            },
            defaultCountry: "VN",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePhoneNumber = (event) => {
        setErrors("");
        if (rx_live.test(event.target.value)) setPhoneNumber(event.target.value);
    };

    const handleRedirect = async () => {
        if (shareCode) {
            history.push(`/share/${shareCode}`);
            return;
        }

        if (params) {
            history.push(`/?product_id=${params.product_id}&sharer_id=${params.sharer_id}`);
            return;
        }

        history.push("/");
        window.location.reload();
    };

    const onLogin = () => {
        showLoading();
        window.confirmationResult
            .confirm(otp)
            .then((confirmationResult) => {
                const { accessToken } = confirmationResult.user._delegate;

                authAPI
                    .loginWithPhone({
                        firebaseToken: accessToken,
                    })
                    .then((res) => {
                        hideLoading();
                        dispatch(setUserInfoStore(res.results));

                        handleRedirect();
                    })
                    .catch((err) => {
                        hideLoading();
                        error("Đăng nhập thất bại, vui lòng thử lại!");
                    });
            })
            .catch((err) => {
                // User couldn't sign in (bad verification code?)
                hideLoading();
                error("Mã OTP không hợp lệ, vui lòng thử lại!");
            });
    };

    const onChangeOtp = (val) => setOtp(val);

    const onOtpAgain = () => {
        sendPhoneNumber();
    };

    const sendPhoneNumber = () => {
        const checkLength = phoneNumber?.length == 10 ? true : false;
        if (checkLength) {
            // setStep(3);
            let phone = form.getFieldValue("phone");
            if (phone.startsWith("0")) phone = `+84${phone.substring(1)}`;

            const appVerifier = window.recaptchaVerifier;
            // console.log("ahha",appVerifier)

            showLoading();
            auth.signInWithPhoneNumber(phone, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).

                    window.confirmationResult = confirmationResult;
                    // ...
                    hideLoading();
                    setStep(3);
                })
                .catch((err) => {
                    // Error; SMS not sent
                    // ...
                    hideLoading();
                    error("Đăng nhập thất bại, vui lòng thử lại!");
                });
        } else {
            setErrors("Số điện thoại không hợp lệ!");
        }
    };

    const loginWithGoogle = () => {
        showLoading();
        signInWithGoogle()
            .then((res) => {
                const { idToken } = res.credential;

                authAPI
                    .loginWithGoogle({
                        googleToken: idToken,
                    })
                    .then((res) => {
                        // console.log("results: ", res.results);
                        dispatch(setUserInfoStore(res.results));

                        handleRedirect();
                    })
                    .catch((err) => {
                        console.log("err1: ", err);
                    });
                hideLoading();
            })
            .catch((err) => {
                hideLoading();
                error("Đăng nhập thất bại, vui lòng thử lại!");
                console.log("error: ", { data: err });
            });
    };

    const handleKeyUp = (event) => {
        // Enter
        if (event.keyCode === 13) {
            formRef.current.submit();
        }
    };

    const responseFacebook = async (response) => {
        const accessToken = response?.accessToken;
        showLoading();
        if (accessToken) {
            try {
                const res = await authAPI.loginWithFacebook({
                    facebookToken: accessToken,
                });
                dispatch(setUserInfoStore(res.results));
                handleRedirect();
                hideLoading();
            } catch (err) {
                hideLoading();
                console.log(err);
                error("Đăng nhập thất bại, vui lòng thử lại!");
            }
        }
        hideLoading();
    };

    const handleLoginGoogle = async (response) => {
        const accessToken = response?.tokenId;
        showLoading();
        console.log("response gg: ", response);

        try {
            const res = await authAPI.loginWithGoogle({
                googleToken: accessToken,
            });
            dispatch(setUserInfoStore(res.results));
            handleRedirect();
            hideLoading();
        } catch (err) {
            hideLoading();
            console.log(err);
            error("Đăng nhập thất bại, vui lòng thử lại!");
        }
    };

    const handleFailureGoogle = (googleData) => {
        console.log(googleData);
        // error("Đăng nhập thất bại, vui lòng thử lại!");
    };

    return (
        <div className="d-flex flex-column justify-content-between div-login">
            <Form form={form} ref={formRef} onKeyUp={handleKeyUp}>
                <div className="div-logo-login">
                    <img
                        src={logo}
                        alt="logo"
                        // width="156"
                        height="64"
                        style={{ objectFit: "cover" }}
                    />
                </div>
                <div className="text-center" style={step !== 3 ? { marginTop: "16px" } : { marginTop: "56px" }}>
                    {step === 1 ? (
                        <strong>Xin chào</strong>
                    ) : step === 2 ? (
                        <strong>Đăng nhập</strong>
                    ) : (
                        <>
                            <p className="mb-0">Vui lòng nhập OTP từ số điện thoại:</p>
                            <p className="mb-0">{form.getFieldValue("phone")}</p>
                        </>
                    )}
                </div>

                <div
                    className="div-form-login text-center"
                    style={step !== 3 ? { marginTop: "86px" } : { marginTop: "64px" }}
                >
                    {step === 1 ? (
                        <>
                            <Button
                                onClick={() => setStep(2)}
                                type="primary"
                                size="large"
                                className="w-100"
                                style={{ marginBottom: "32px" }}
                            >
                                Đăng nhập bằng số điện thoại
                            </Button>

                            <p className="mb-0 text-grey-login">Hoặc đăng nhập với</p>

                            <div className="d-flex justify-content-center div-icon-login">
                                {/* <img
                                    alt=""
                                    src={icon_facebook}
                                    width={44}
                                    height={44}
                                    style={{ marginRight: "24px", cursor: "pointer" }}
                                    onClick={loginWithFb}
                                /> */}
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                                    fields="name,email,picture"
                                    disableMobileRedirect={true}
                                    callback={responseFacebook}
                                    cssClass="my-facebook-button-class"
                                    textButton={null}
                                    onClick={showLoading}
                                    icon={
                                        <img
                                            alt=""
                                            src={icon_facebook}
                                            width={44}
                                            height={44}
                                            style={{ marginRight: "24px", cursor: "pointer" }}
                                        />
                                    }
                                />

                                {/* <img
									alt=""
									src={icon_google}
									width={44}
									height={44}
									onClick={loginWithGoogle}
									style={{ cursor: "pointer" }}
								/> */}
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    onSuccess={handleLoginGoogle}
                                    onFailure={handleFailureGoogle}
                                    cookiePolicy={"single_host_origin"}
                                    render={(renderProps) => (
                                        <img
                                            alt=""
                                            src={icon_google}
                                            width={44}
                                            height={44}
                                            onClick={renderProps.onClick}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}
                                ></GoogleLogin>
                            </div>
                        </>
                    ) : step === 2 ? (
                        <>
                            <Form.Item name="phone" className="mb-0">
                                <div>
                                    <Input
                                        type="text"
                                        id="phone"
                                        size="large"
                                        className="w-100"
                                        maxLength="10"
                                        pattern="[0-9]*"
                                        placeholder="Số điện thoại của bạn"
                                        onChange={handleChangePhoneNumber}
                                        value={phoneNumber}
                                    />
                                </div>
                                {/* <Input
									placeholder="Số điện thoại của bạn"
									size="large"
									className="w-100"
									maxLength="10"
								/> */}
                            </Form.Item>
                            <div className="text-danger" style={{ display: "flex", alignItems: "left" }}>
                                {errors}
                            </div>
                            <Button
                                htmlType="submit"
                                onClick={sendPhoneNumber}
                                type="primary"
                                size="large"
                                className="w-100"
                                style={{ marginTop: "65px" }}
                                disabled={phoneNumber?.length == 10 ? false : true}
                            >
                                Tiếp tục
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className="div-otp-login d-flex justify-content-center">
                                <OtpInput
                                    name="otp_value"
                                    value={otp}
                                    shouldAutoFocus={true}
                                    onChange={onChangeOtp}
                                    numInputs={6}
                                    // separator={<span>-</span>}
                                    className="otp-login"
                                    isInputNum={true}
                                />
                            </div>
                            <Button
                                htmlType="submit"
                                onClick={onLogin}
                                type="primary"
                                size="large"
                                className="w-100"
                                style={{ marginTop: "65px" }}
                                disabled={otp?.length == 6 ? false : true}
                            >
                                Tiếp tục
                            </Button>
                            <p className="mb-0 text-grey-login" style={{ marginTop: "16px" }}>
                                Bạn không nhận được mã OTP:{" "}
                                <span
                                    onClick={onOtpAgain}
                                    className="cursor text-blue-login"
                                    style={{ cursor: "pointer" }}
                                >
                                    GỬI LẠI
                                </span>
                            </p>
                        </>
                    )}
                </div>
            </Form>

            <div className="text-center">
                <p className="mb-0 text-grey-login">
                    Bằng việc tạo tài khoản, bạn đã đồng ý với những{" "}
                    <span className="mb-0 fw-bold text-bold-login">
                        <Link to="/policy">Điều khoản</Link>
                    </span>{" "}
                    và{" "}
                    <span className="mb-0 fw-bold text-bold-login">
                        <Link to="/rules">Nội quy</Link>
                    </span>{" "}
                    của chúng tôi.
                </p>
            </div>
            {/* <div ref={capchaRef}> */}
            <div id="recaptcha-container" visibility="hidden" data-size="invisible"></div>
            {/* </div> */}
        </div>
    );
};

export default Login;
