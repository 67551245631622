import { Skeleton } from "@material-ui/lab";
import { Divider } from "antd";
import classNames from "classnames";
import React from "react";

function UserOrderSkeletonList({ length = 5 }) {
	const data = Array.from(Array(length).keys());

	const isMobile = window.innerWidth < 768;

	return (
		<div className="user-order-skeleton-list">
			{data.map((item) => (
				<React.Fragment key={item}>
					<div className="user-order-body">
						<div className="user-order-left">
							<div className="product-img">
								<Skeleton className="img" animation="wave" variant="rect" width={74} height={74} />
							</div>
							<div className="product-info">
								<Skeleton
									className={classNames("text-1", { "text-1-mobile": isMobile })}
									animation="wave"
									variant="rect"
									height={20}
								/>
								<Skeleton
									className={classNames("text-2", { "text-2-mobile": isMobile })}
									animation="wave"
									variant="rect"
									height={20}
								/>
								<Skeleton
									className={classNames("text-3", { "text-3-mobile": isMobile })}
									animation="wave"
									variant="rect"
									height={20}
								/>
							</div>
						</div>
						<div className="user-order-right">
							<Skeleton
								className={classNames("text", { "text-mobile": isMobile })}
								animation="wave"
								variant="rect"
								height={20}
							/>
						</div>
					</div>

					<div className="user-order-bottom">
						<Divider className="my-2" />
						<div className="bottom-box">
							<Skeleton className="text" animation="wave" variant="rect" width={130} height={20} />
							<Skeleton className="text" animation="wave" variant="rect" width={130} height={20} />
						</div>
					</div>
					<Divider className="my-2 divider-bottom-line" />
				</React.Fragment>
			))}
		</div>
	);
}

export default UserOrderSkeletonList;
