import { Skeleton } from "@material-ui/lab";
import React from "react";

function BonusStatisticalBodySkeleton({ length = 10 }) {
	const data = Array.from(Array(length).keys());

	// console.log("length: ", length);

	return (
		<div className="bonus-statistical-body-skeleton-container">
			{data.map((item) => (
				<div key={item} className="item-bonus-statistical-body-skeleton">
					<div className="bonus-statistical-left">
						<Skeleton className="" animation="wave" variant="text" width={130} height={30} />
						<Skeleton className="" animation="wave" variant="text" width={180} height={30} />
						<Skeleton className="" animation="wave" variant="text" width={130} height={30} />
						<Skeleton className="" animation="wave" variant="text" width={180} height={30} />
					</div>
					<div className="bonus-statistical-right">
						<Skeleton className="" animation="wave" variant="text" width={100} height={30} />
					</div>
				</div>
			))}
		</div>
	);
}

export default BonusStatisticalBodySkeleton;
