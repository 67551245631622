import { Divider } from "antd";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { ActivityOrder } from "src/constants/types";
import { getNumberWithCommas } from "src/utils/numberUtils";

const OrderItem = (props) => {
	const history = useHistory();
	const status = [
		{ activity: ActivityOrder.PROCESSING, color: "#FBBC12", label: "Đang xác nhận" },
		{ activity: ActivityOrder.COMPLETED, color: "#547DBF", label: "Hoàn thành" },
		{ activity: ActivityOrder.CANCELLED, color: "#EB4335", label: "Đã huỷ" },
		{ activity: ActivityOrder.CONFIRMED, color: "green", label: "Đang giao" },
	];
	const randomItem = Math.floor(Math.random() * props.item?.order_items.length);
	const itemShow = props.item?.order_items?.[randomItem];

	return (
		<div onClick={() => history.push(`/order/${itemShow?.id}`)}>
			<div className="d-flex" style={{ fontSize: "14px" }}>
				<img
					alt=""
					src={itemShow?.thumbnail}
					width={90}
					height={90}
					style={{ objectFit: "cover", marginRight: "18px" }}
				/>
				<div style={{ width: "70%" }}>
					<p
						className="mb-1"
						style={{ color: _.find(status, ["activity", props.item?.activity])?.color }}
					>
						{_.find(status, ["activity", props.item?.activity])?.label}
					</p>
					<p className="mb-1">
						{itemShow?.title.length > 60
							? itemShow?.title.substring(0, 60) + "..."
							: itemShow?.title}
					</p>
					<div className="d-flex justify-content-between">
						<p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: 0 }}>
							{getNumberWithCommas(itemShow?.price || 0)}đ
							<span
								style={{
									textDecoration: "line-through",
									color: "#808080",
									fontSize: "12px",
									marginLeft: "5px",
								}}
							>
								{getNumberWithCommas(itemShow?.product?.fake_price || 0)} đ
							</span>
						</p>
						<strong>x{itemShow?.quantity}</strong>
					</div>
				</div>
			</div>
			<Divider className="my-2" />
			<div className="d-flex justify-content-between pb-1" style={{ fontSize: "14px" }}>
				<span>
					Đơn hàng <strong>#{props.item?.id.substring(0, 8)}</strong>
				</span>
				<span>
					Thành tiền: <strong>{getNumberWithCommas(props.item?.fee_total || 0)}đ</strong>
				</span>
			</div>
			{/* <div className="div-space" style={{ margin: "0 -12px" }} /> */}
		</div>
	);
};

export default OrderItem;
